import api from "@/api";

export default {
  namespaced: true,
  state: {
    countries: [
      {
        _id: "6148623b04eb7ba0ca880d8e",
        englishShortName: "Afghanistan",
        alpha2Code: "AF",
        alpha3Code: "AFG",
        numericCode: 4,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.326Z",
        updatedAt: "2021-09-20T10:28:11.326Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d92",
        englishShortName: "Andorra",
        alpha2Code: "AD",
        alpha3Code: "AND",
        numericCode: 20,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.326Z",
        updatedAt: "2021-09-20T10:28:11.326Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d93",
        englishShortName: "Angola",
        alpha2Code: "AO",
        alpha3Code: "AGO",
        numericCode: 24,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.326Z",
        updatedAt: "2021-09-20T10:28:11.326Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d94",
        englishShortName: "Anguilla",
        alpha2Code: "AI",
        alpha3Code: "AIA",
        numericCode: 660,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d95",
        englishShortName: "Antarctica",
        alpha2Code: "AQ",
        alpha3Code: "ATA",
        numericCode: 10,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d96",
        englishShortName: "Antigua and Barbuda",
        alpha2Code: "AG",
        alpha3Code: "ATG",
        numericCode: 28,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d97",
        englishShortName: "Argentina",
        alpha2Code: "AR",
        alpha3Code: "ARG",
        numericCode: 32,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d98",
        englishShortName: "Armenia",
        alpha2Code: "AM",
        alpha3Code: "ARM",
        numericCode: 51,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d99",
        englishShortName: "Aruba",
        alpha2Code: "AW",
        alpha3Code: "ABW",
        numericCode: 533,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d9a",
        englishShortName: "Australia",
        alpha2Code: "AU",
        alpha3Code: "AUS",
        numericCode: 36,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d9b",
        englishShortName: "Austria",
        alpha2Code: "AT",
        alpha3Code: "AUT",
        numericCode: 40,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d9c",
        englishShortName: "Azerbaijan",
        alpha2Code: "AZ",
        alpha3Code: "AZE",
        numericCode: 31,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d9d",
        englishShortName: "Bahamas (the)",
        alpha2Code: "BS",
        alpha3Code: "BHS",
        numericCode: 44,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d9e",
        englishShortName: "Bahrain",
        alpha2Code: "BH",
        alpha3Code: "BHR",
        numericCode: 48,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d9f",
        englishShortName: "Bangladesh",
        alpha2Code: "BD",
        alpha3Code: "BGD",
        numericCode: 50,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da0",
        englishShortName: "Barbados",
        alpha2Code: "BB",
        alpha3Code: "BRB",
        numericCode: 52,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da1",
        englishShortName: "Belarus",
        alpha2Code: "BY",
        alpha3Code: "BLR",
        numericCode: 112,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da2",
        englishShortName: "Belgium",
        alpha2Code: "BE",
        alpha3Code: "BEL",
        numericCode: 56,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da3",
        englishShortName: "Belize",
        alpha2Code: "BZ",
        alpha3Code: "BLZ",
        numericCode: 84,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da4",
        englishShortName: "Benin",
        alpha2Code: "BJ",
        alpha3Code: "BEN",
        numericCode: 204,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d8f",
        englishShortName: "Albania",
        alpha2Code: "AL",
        alpha3Code: "ALB",
        numericCode: 8,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.326Z",
        updatedAt: "2021-09-20T10:28:11.326Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da5",
        englishShortName: "Bermuda",
        alpha2Code: "BM",
        alpha3Code: "BMU",
        numericCode: 60,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.327Z",
        updatedAt: "2021-09-20T10:28:11.327Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da6",
        englishShortName: "Bhutan",
        alpha2Code: "BT",
        alpha3Code: "BTN",
        numericCode: 64,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da7",
        englishShortName: "Bolivia (Plurinational State of)",
        alpha2Code: "BO",
        alpha3Code: "BOL",
        numericCode: 68,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da8",
        englishShortName: "Bonaire, Sint Eustatius and Saba",
        alpha2Code: "BQ",
        alpha3Code: "BES",
        numericCode: 535,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880da9",
        englishShortName: "Bosnia and Herzegovina",
        alpha2Code: "BA",
        alpha3Code: "BIH",
        numericCode: 70,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880daa",
        englishShortName: "Botswana",
        alpha2Code: "BW",
        alpha3Code: "BWA",
        numericCode: 72,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d90",
        englishShortName: "Algeria",
        alpha2Code: "DZ",
        alpha3Code: "DZA",
        numericCode: 12,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.326Z",
        updatedAt: "2021-09-20T10:28:11.326Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dab",
        englishShortName: "Bouvet Island",
        alpha2Code: "BV",
        alpha3Code: "BVT",
        numericCode: 74,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dac",
        englishShortName: "Brazil",
        alpha2Code: "BR",
        alpha3Code: "BRA",
        numericCode: 76,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880d91",
        englishShortName: "American Samoa",
        alpha2Code: "AS",
        alpha3Code: "ASM",
        numericCode: 16,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.326Z",
        updatedAt: "2021-09-20T10:28:11.326Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dad",
        englishShortName: "British Indian Ocean Territory (the)",
        alpha2Code: "IO",
        alpha3Code: "IOT",
        numericCode: 86,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dae",
        englishShortName: "Brunei Darussalam",
        alpha2Code: "BN",
        alpha3Code: "BRN",
        numericCode: 96,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880daf",
        englishShortName: "Bulgaria",
        alpha2Code: "BG",
        alpha3Code: "BGR",
        numericCode: 100,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db0",
        englishShortName: "Burkina Faso",
        alpha2Code: "BF",
        alpha3Code: "BFA",
        numericCode: 854,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db1",
        englishShortName: "Burundi",
        alpha2Code: "BI",
        alpha3Code: "BDI",
        numericCode: 108,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db2",
        englishShortName: "Cabo Verde",
        alpha2Code: "CV",
        alpha3Code: "CPV",
        numericCode: 132,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db3",
        englishShortName: "Cambodia",
        alpha2Code: "KH",
        alpha3Code: "KHM",
        numericCode: 116,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db4",
        englishShortName: "Cameroon",
        alpha2Code: "CM",
        alpha3Code: "CMR",
        numericCode: 120,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db5",
        englishShortName: "Canada",
        alpha2Code: "CA",
        alpha3Code: "CAN",
        numericCode: 124,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db6",
        englishShortName: "Cayman Islands (the)",
        alpha2Code: "KY",
        alpha3Code: "CYM",
        numericCode: 136,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db7",
        englishShortName: "Central African Republic (the)",
        alpha2Code: "CF",
        alpha3Code: "CAF",
        numericCode: 140,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db8",
        englishShortName: "Chad",
        alpha2Code: "TD",
        alpha3Code: "TCD",
        numericCode: 148,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880db9",
        englishShortName: "Chile",
        alpha2Code: "CL",
        alpha3Code: "CHL",
        numericCode: 152,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dba",
        englishShortName: "China",
        alpha2Code: "CN",
        alpha3Code: "CHN",
        numericCode: 156,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dbb",
        englishShortName: "Christmas Island",
        alpha2Code: "CX",
        alpha3Code: "CXR",
        numericCode: 162,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dbc",
        englishShortName: "Cocos (Keeling) Islands (the)",
        alpha2Code: "CC",
        alpha3Code: "CCK",
        numericCode: 166,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dbd",
        englishShortName: "Colombia",
        alpha2Code: "CO",
        alpha3Code: "COL",
        numericCode: 170,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dbe",
        englishShortName: "Comoros (the)",
        alpha2Code: "KM",
        alpha3Code: "COM",
        numericCode: 174,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dbf",
        englishShortName: "Congo (the Democratic Republic of the)",
        alpha2Code: "CD",
        alpha3Code: "COD",
        numericCode: 180,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc0",
        englishShortName: "Congo (the)",
        alpha2Code: "CG",
        alpha3Code: "COG",
        numericCode: 178,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc1",
        englishShortName: "Cook Islands (the)",
        alpha2Code: "CK",
        alpha3Code: "COK",
        numericCode: 184,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc2",
        englishShortName: "Costa Rica",
        alpha2Code: "CR",
        alpha3Code: "CRI",
        numericCode: 188,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc3",
        englishShortName: "Croatia",
        alpha2Code: "HR",
        alpha3Code: "HRV",
        numericCode: 191,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc4",
        englishShortName: "Cuba",
        alpha2Code: "CU",
        alpha3Code: "CUB",
        numericCode: 192,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc5",
        englishShortName: "Curaçao",
        alpha2Code: "CW",
        alpha3Code: "CUW",
        numericCode: 531,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc6",
        englishShortName: "Cyprus",
        alpha2Code: "CY",
        alpha3Code: "CYP",
        numericCode: 196,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc7",
        englishShortName: "Czechia",
        alpha2Code: "CZ",
        alpha3Code: "CZE",
        numericCode: 203,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc8",
        englishShortName: "Côte d'Ivoire",
        alpha2Code: "CI",
        alpha3Code: "CIV",
        numericCode: 384,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dc9",
        englishShortName: "Denmark",
        alpha2Code: "DK",
        alpha3Code: "DNK",
        numericCode: 208,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dca",
        englishShortName: "Djibouti",
        alpha2Code: "DJ",
        alpha3Code: "DJI",
        numericCode: 262,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dcb",
        englishShortName: "Dominica",
        alpha2Code: "DM",
        alpha3Code: "DMA",
        numericCode: 212,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.328Z",
        updatedAt: "2021-09-20T10:28:11.328Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dcc",
        englishShortName: "Dominican Republic (the)",
        alpha2Code: "DO",
        alpha3Code: "DOM",
        numericCode: 214,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dcd",
        englishShortName: "Ecuador",
        alpha2Code: "EC",
        alpha3Code: "ECU",
        numericCode: 218,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dce",
        englishShortName: "Egypt",
        alpha2Code: "EG",
        alpha3Code: "EGY",
        numericCode: 818,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dcf",
        englishShortName: "El Salvador",
        alpha2Code: "SV",
        alpha3Code: "SLV",
        numericCode: 222,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd0",
        englishShortName: "Equatorial Guinea",
        alpha2Code: "GQ",
        alpha3Code: "GNQ",
        numericCode: 226,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd1",
        englishShortName: "Eritrea",
        alpha2Code: "ER",
        alpha3Code: "ERI",
        numericCode: 232,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd2",
        englishShortName: "Estonia",
        alpha2Code: "EE",
        alpha3Code: "EST",
        numericCode: 233,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd3",
        englishShortName: "Eswatini",
        alpha2Code: "SZ",
        alpha3Code: "SWZ",
        numericCode: 748,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd4",
        englishShortName: "Ethiopia",
        alpha2Code: "ET",
        alpha3Code: "ETH",
        numericCode: 231,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd5",
        englishShortName: "Falkland Islands (the) [Malvinas]",
        alpha2Code: "FK",
        alpha3Code: "FLK",
        numericCode: 238,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd6",
        englishShortName: "Faroe Islands (the)",
        alpha2Code: "FO",
        alpha3Code: "FRO",
        numericCode: 234,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd7",
        englishShortName: "Fiji",
        alpha2Code: "FJ",
        alpha3Code: "FJI",
        numericCode: 242,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd8",
        englishShortName: "Finland",
        alpha2Code: "FI",
        alpha3Code: "FIN",
        numericCode: 246,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dd9",
        englishShortName: "France",
        alpha2Code: "FR",
        alpha3Code: "FRA",
        numericCode: 250,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dda",
        englishShortName: "French Guiana",
        alpha2Code: "GF",
        alpha3Code: "GUF",
        numericCode: 254,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880ddb",
        englishShortName: "French Polynesia",
        alpha2Code: "PF",
        alpha3Code: "PYF",
        numericCode: 258,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880ddc",
        englishShortName: "French Southern Territories (the)",
        alpha2Code: "TF",
        alpha3Code: "ATF",
        numericCode: 260,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880ddd",
        englishShortName: "Gabon",
        alpha2Code: "GA",
        alpha3Code: "GAB",
        numericCode: 266,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dde",
        englishShortName: "Gambia (the)",
        alpha2Code: "GM",
        alpha3Code: "GMB",
        numericCode: 270,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880ddf",
        englishShortName: "Georgia",
        alpha2Code: "GE",
        alpha3Code: "GEO",
        numericCode: 268,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de0",
        englishShortName: "Germany",
        alpha2Code: "DE",
        alpha3Code: "DEU",
        numericCode: 276,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de1",
        englishShortName: "Ghana",
        alpha2Code: "GH",
        alpha3Code: "GHA",
        numericCode: 288,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.329Z",
        updatedAt: "2021-09-20T10:28:11.329Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de2",
        englishShortName: "Gibraltar",
        alpha2Code: "GI",
        alpha3Code: "GIB",
        numericCode: 292,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de3",
        englishShortName: "Greece",
        alpha2Code: "GR",
        alpha3Code: "GRC",
        numericCode: 300,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de4",
        englishShortName: "Greenland",
        alpha2Code: "GL",
        alpha3Code: "GRL",
        numericCode: 304,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de5",
        englishShortName: "Grenada",
        alpha2Code: "GD",
        alpha3Code: "GRD",
        numericCode: 308,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de6",
        englishShortName: "Guadeloupe",
        alpha2Code: "GP",
        alpha3Code: "GLP",
        numericCode: 312,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de7",
        englishShortName: "Guam",
        alpha2Code: "GU",
        alpha3Code: "GUM",
        numericCode: 316,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de8",
        englishShortName: "Guatemala",
        alpha2Code: "GT",
        alpha3Code: "GTM",
        numericCode: 320,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880de9",
        englishShortName: "Guernsey",
        alpha2Code: "GG",
        alpha3Code: "GGY",
        numericCode: 831,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dea",
        englishShortName: "Guinea",
        alpha2Code: "GN",
        alpha3Code: "GIN",
        numericCode: 324,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880deb",
        englishShortName: "Guinea-Bissau",
        alpha2Code: "GW",
        alpha3Code: "GNB",
        numericCode: 624,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dec",
        englishShortName: "Guyana",
        alpha2Code: "GY",
        alpha3Code: "GUY",
        numericCode: 328,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880ded",
        englishShortName: "Haiti",
        alpha2Code: "HT",
        alpha3Code: "HTI",
        numericCode: 332,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dee",
        englishShortName: "Heard Island and McDonald Islands",
        alpha2Code: "HM",
        alpha3Code: "HMD",
        numericCode: 334,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880def",
        englishShortName: "Holy See (the)",
        alpha2Code: "VA",
        alpha3Code: "VAT",
        numericCode: 336,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df0",
        englishShortName: "Honduras",
        alpha2Code: "HN",
        alpha3Code: "HND",
        numericCode: 340,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df1",
        englishShortName: "Hong Kong",
        alpha2Code: "HK",
        alpha3Code: "HKG",
        numericCode: 344,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df2",
        englishShortName: "Hungary",
        alpha2Code: "HU",
        alpha3Code: "HUN",
        numericCode: 348,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df3",
        englishShortName: "Iceland",
        alpha2Code: "IS",
        alpha3Code: "ISL",
        numericCode: 352,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df4",
        englishShortName: "India",
        alpha2Code: "IN",
        alpha3Code: "IND",
        numericCode: 356,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df5",
        englishShortName: "Indonesia",
        alpha2Code: "ID",
        alpha3Code: "IDN",
        numericCode: 360,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df6",
        englishShortName: "Iran (Islamic Republic of)",
        alpha2Code: "IR",
        alpha3Code: "IRN",
        numericCode: 364,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df7",
        englishShortName: "Iraq",
        alpha2Code: "IQ",
        alpha3Code: "IRQ",
        numericCode: 368,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df8",
        englishShortName: "Ireland",
        alpha2Code: "IE",
        alpha3Code: "IRL",
        numericCode: 372,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880df9",
        englishShortName: "Isle of Man",
        alpha2Code: "IM",
        alpha3Code: "IMN",
        numericCode: 833,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dfa",
        englishShortName: "Israel",
        alpha2Code: "IL",
        alpha3Code: "ISR",
        numericCode: 376,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dfb",
        englishShortName: "Italy",
        alpha2Code: "IT",
        alpha3Code: "ITA",
        numericCode: 380,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dfc",
        englishShortName: "Jamaica",
        alpha2Code: "JM",
        alpha3Code: "JAM",
        numericCode: 388,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dfd",
        englishShortName: "Japan",
        alpha2Code: "JP",
        alpha3Code: "JPN",
        numericCode: 392,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dfe",
        englishShortName: "Jersey",
        alpha2Code: "JE",
        alpha3Code: "JEY",
        numericCode: 832,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880dff",
        englishShortName: "Jordan",
        alpha2Code: "JO",
        alpha3Code: "JOR",
        numericCode: 400,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e00",
        englishShortName: "Kazakhstan",
        alpha2Code: "KZ",
        alpha3Code: "KAZ",
        numericCode: 398,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e01",
        englishShortName: "Kenya",
        alpha2Code: "KE",
        alpha3Code: "KEN",
        numericCode: 404,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e02",
        englishShortName: "Kiribati",
        alpha2Code: "KI",
        alpha3Code: "KIR",
        numericCode: 296,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e03",
        englishShortName: "Korea (the Democratic People's Republic of)",
        alpha2Code: "KP",
        alpha3Code: "PRK",
        numericCode: 408,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e04",
        englishShortName: "Korea (the Republic of)",
        alpha2Code: "KR",
        alpha3Code: "KOR",
        numericCode: 410,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e05",
        englishShortName: "Kuwait",
        alpha2Code: "KW",
        alpha3Code: "KWT",
        numericCode: 414,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e06",
        englishShortName: "Kyrgyzstan",
        alpha2Code: "KG",
        alpha3Code: "KGZ",
        numericCode: 417,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.330Z",
        updatedAt: "2021-09-20T10:28:11.330Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e07",
        englishShortName: "Lao People's Democratic Republic (the)",
        alpha2Code: "LA",
        alpha3Code: "LAO",
        numericCode: 418,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e08",
        englishShortName: "Latvia",
        alpha2Code: "LV",
        alpha3Code: "LVA",
        numericCode: 428,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e09",
        englishShortName: "Lebanon",
        alpha2Code: "LB",
        alpha3Code: "LBN",
        numericCode: 422,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e0a",
        englishShortName: "Lesotho",
        alpha2Code: "LS",
        alpha3Code: "LSO",
        numericCode: 426,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e0b",
        englishShortName: "Liberia",
        alpha2Code: "LR",
        alpha3Code: "LBR",
        numericCode: 430,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e0c",
        englishShortName: "Libya",
        alpha2Code: "LY",
        alpha3Code: "LBY",
        numericCode: 434,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e0d",
        englishShortName: "Liechtenstein",
        alpha2Code: "LI",
        alpha3Code: "LIE",
        numericCode: 438,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e0e",
        englishShortName: "Lithuania",
        alpha2Code: "LT",
        alpha3Code: "LTU",
        numericCode: 440,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e0f",
        englishShortName: "Luxembourg",
        alpha2Code: "LU",
        alpha3Code: "LUX",
        numericCode: 442,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e10",
        englishShortName: "Macao",
        alpha2Code: "MO",
        alpha3Code: "MAC",
        numericCode: 446,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e11",
        englishShortName: "Madagascar",
        alpha2Code: "MG",
        alpha3Code: "MDG",
        numericCode: 450,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e12",
        englishShortName: "Malawi",
        alpha2Code: "MW",
        alpha3Code: "MWI",
        numericCode: 454,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e13",
        englishShortName: "Malaysia",
        alpha2Code: "MY",
        alpha3Code: "MYS",
        numericCode: 458,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e14",
        englishShortName: "Maldives",
        alpha2Code: "MV",
        alpha3Code: "MDV",
        numericCode: 462,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e15",
        englishShortName: "Mali",
        alpha2Code: "ML",
        alpha3Code: "MLI",
        numericCode: 466,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e16",
        englishShortName: "Malta",
        alpha2Code: "MT",
        alpha3Code: "MLT",
        numericCode: 470,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e17",
        englishShortName: "Marshall Islands (the)",
        alpha2Code: "MH",
        alpha3Code: "MHL",
        numericCode: 584,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e18",
        englishShortName: "Martinique",
        alpha2Code: "MQ",
        alpha3Code: "MTQ",
        numericCode: 474,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e19",
        englishShortName: "Mauritania",
        alpha2Code: "MR",
        alpha3Code: "MRT",
        numericCode: 478,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e1a",
        englishShortName: "Mauritius",
        alpha2Code: "MU",
        alpha3Code: "MUS",
        numericCode: 480,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e1b",
        englishShortName: "Mayotte",
        alpha2Code: "YT",
        alpha3Code: "MYT",
        numericCode: 175,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e1c",
        englishShortName: "Mexico",
        alpha2Code: "MX",
        alpha3Code: "MEX",
        numericCode: 484,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e1d",
        englishShortName: "Micronesia (Federated States of)",
        alpha2Code: "FM",
        alpha3Code: "FSM",
        numericCode: 583,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e1e",
        englishShortName: "Moldova (the Republic of)",
        alpha2Code: "MD",
        alpha3Code: "MDA",
        numericCode: 498,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e1f",
        englishShortName: "Monaco",
        alpha2Code: "MC",
        alpha3Code: "MCO",
        numericCode: 492,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e20",
        englishShortName: "Mongolia",
        alpha2Code: "MN",
        alpha3Code: "MNG",
        numericCode: 496,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e21",
        englishShortName: "Montenegro",
        alpha2Code: "ME",
        alpha3Code: "MNE",
        numericCode: 499,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e22",
        englishShortName: "Montserrat",
        alpha2Code: "MS",
        alpha3Code: "MSR",
        numericCode: 500,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e23",
        englishShortName: "Morocco",
        alpha2Code: "MA",
        alpha3Code: "MAR",
        numericCode: 504,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e24",
        englishShortName: "Mozambique",
        alpha2Code: "MZ",
        alpha3Code: "MOZ",
        numericCode: 508,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e25",
        englishShortName: "Myanmar",
        alpha2Code: "MM",
        alpha3Code: "MMR",
        numericCode: 104,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e26",
        englishShortName: "Namibia",
        alpha2Code: "NA",
        alpha3Code: "NAM",
        numericCode: 516,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e27",
        englishShortName: "Nauru",
        alpha2Code: "NR",
        alpha3Code: "NRU",
        numericCode: 520,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e28",
        englishShortName: "Nepal",
        alpha2Code: "NP",
        alpha3Code: "NPL",
        numericCode: 524,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e29",
        englishShortName: "Netherlands (the)",
        alpha2Code: "NL",
        alpha3Code: "NLD",
        numericCode: 528,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e2a",
        englishShortName: "New Caledonia",
        alpha2Code: "NC",
        alpha3Code: "NCL",
        numericCode: 540,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e2b",
        englishShortName: "New Zealand",
        alpha2Code: "NZ",
        alpha3Code: "NZL",
        numericCode: 554,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e2c",
        englishShortName: "Nicaragua",
        alpha2Code: "NI",
        alpha3Code: "NIC",
        numericCode: 558,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e2d",
        englishShortName: "Niger (the)",
        alpha2Code: "NE",
        alpha3Code: "NER",
        numericCode: 562,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e2e",
        englishShortName: "Nigeria",
        alpha2Code: "NG",
        alpha3Code: "NGA",
        numericCode: 566,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.331Z",
        updatedAt: "2021-09-20T10:28:11.331Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e2f",
        englishShortName: "Niue",
        alpha2Code: "NU",
        alpha3Code: "NIU",
        numericCode: 570,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e30",
        englishShortName: "Norfolk Island",
        alpha2Code: "NF",
        alpha3Code: "NFK",
        numericCode: 574,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e31",
        englishShortName: "North Macedonia",
        alpha2Code: "MK",
        alpha3Code: "MKD",
        numericCode: 807,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e32",
        englishShortName: "Northern Mariana Islands (the)",
        alpha2Code: "MP",
        alpha3Code: "MNP",
        numericCode: 580,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e33",
        englishShortName: "Norway",
        alpha2Code: "NO",
        alpha3Code: "NOR",
        numericCode: 578,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e34",
        englishShortName: "Oman",
        alpha2Code: "OM",
        alpha3Code: "OMN",
        numericCode: 512,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e35",
        englishShortName: "Pakistan",
        alpha2Code: "PK",
        alpha3Code: "PAK",
        numericCode: 586,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e36",
        englishShortName: "Palau",
        alpha2Code: "PW",
        alpha3Code: "PLW",
        numericCode: 585,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e37",
        englishShortName: "Palestine, State of",
        alpha2Code: "PS",
        alpha3Code: "PSE",
        numericCode: 275,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e38",
        englishShortName: "Panama",
        alpha2Code: "PA",
        alpha3Code: "PAN",
        numericCode: 591,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e39",
        englishShortName: "Papua New Guinea",
        alpha2Code: "PG",
        alpha3Code: "PNG",
        numericCode: 598,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e3a",
        englishShortName: "Paraguay",
        alpha2Code: "PY",
        alpha3Code: "PRY",
        numericCode: 600,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e3b",
        englishShortName: "Peru",
        alpha2Code: "PE",
        alpha3Code: "PER",
        numericCode: 604,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e3c",
        englishShortName: "Philippines (the)",
        alpha2Code: "PH",
        alpha3Code: "PHL",
        numericCode: 608,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e3d",
        englishShortName: "Pitcairn",
        alpha2Code: "PN",
        alpha3Code: "PCN",
        numericCode: 612,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e3e",
        englishShortName: "Poland",
        alpha2Code: "PL",
        alpha3Code: "POL",
        numericCode: 616,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e3f",
        englishShortName: "Portugal",
        alpha2Code: "PT",
        alpha3Code: "PRT",
        numericCode: 620,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e40",
        englishShortName: "Puerto Rico",
        alpha2Code: "PR",
        alpha3Code: "PRI",
        numericCode: 630,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e41",
        englishShortName: "Qatar",
        alpha2Code: "QA",
        alpha3Code: "QAT",
        numericCode: 634,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e42",
        englishShortName: "Romania",
        alpha2Code: "RO",
        alpha3Code: "ROU",
        numericCode: 642,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e43",
        englishShortName: "Russian Federation (the)",
        alpha2Code: "RU",
        alpha3Code: "RUS",
        numericCode: 643,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e44",
        englishShortName: "Rwanda",
        alpha2Code: "RW",
        alpha3Code: "RWA",
        numericCode: 646,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e45",
        englishShortName: "Réunion",
        alpha2Code: "RE",
        alpha3Code: "REU",
        numericCode: 638,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e46",
        englishShortName: "Saint Barthélemy",
        alpha2Code: "BL",
        alpha3Code: "BLM",
        numericCode: 652,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e47",
        englishShortName: "Saint Helena, Ascension and Tristan da Cunha",
        alpha2Code: "SH",
        alpha3Code: "SHN",
        numericCode: 654,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e48",
        englishShortName: "Saint Kitts and Nevis",
        alpha2Code: "KN",
        alpha3Code: "KNA",
        numericCode: 659,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e49",
        englishShortName: "Saint Lucia",
        alpha2Code: "LC",
        alpha3Code: "LCA",
        numericCode: 662,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e4a",
        englishShortName: "Saint Martin (French part)",
        alpha2Code: "MF",
        alpha3Code: "MAF",
        numericCode: 663,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e4b",
        englishShortName: "Saint Pierre and Miquelon",
        alpha2Code: "PM",
        alpha3Code: "SPM",
        numericCode: 666,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e4c",
        englishShortName: "Saint Vincent and the Grenadines",
        alpha2Code: "VC",
        alpha3Code: "VCT",
        numericCode: 670,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e4d",
        englishShortName: "Samoa",
        alpha2Code: "WS",
        alpha3Code: "WSM",
        numericCode: 882,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e4e",
        englishShortName: "San Marino",
        alpha2Code: "SM",
        alpha3Code: "SMR",
        numericCode: 674,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e4f",
        englishShortName: "Sao Tome and Principe",
        alpha2Code: "ST",
        alpha3Code: "STP",
        numericCode: 678,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e50",
        englishShortName: "Saudi Arabia",
        alpha2Code: "SA",
        alpha3Code: "SAU",
        numericCode: 682,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e51",
        englishShortName: "Senegal",
        alpha2Code: "SN",
        alpha3Code: "SEN",
        numericCode: 686,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e52",
        englishShortName: "Serbia",
        alpha2Code: "RS",
        alpha3Code: "SRB",
        numericCode: 688,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e53",
        englishShortName: "Seychelles",
        alpha2Code: "SC",
        alpha3Code: "SYC",
        numericCode: 690,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e54",
        englishShortName: "Sierra Leone",
        alpha2Code: "SL",
        alpha3Code: "SLE",
        numericCode: 694,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e55",
        englishShortName: "Singapore",
        alpha2Code: "SG",
        alpha3Code: "SGP",
        numericCode: 702,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.332Z",
        updatedAt: "2021-09-20T10:28:11.332Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e56",
        englishShortName: "Sint Maarten (Dutch part)",
        alpha2Code: "SX",
        alpha3Code: "SXM",
        numericCode: 534,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e57",
        englishShortName: "Slovakia",
        alpha2Code: "SK",
        alpha3Code: "SVK",
        numericCode: 703,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e58",
        englishShortName: "Slovenia",
        alpha2Code: "SI",
        alpha3Code: "SVN",
        numericCode: 705,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e59",
        englishShortName: "Solomon Islands",
        alpha2Code: "SB",
        alpha3Code: "SLB",
        numericCode: 90,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e5a",
        englishShortName: "Somalia",
        alpha2Code: "SO",
        alpha3Code: "SOM",
        numericCode: 706,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e5b",
        englishShortName: "South Africa",
        alpha2Code: "ZA",
        alpha3Code: "ZAF",
        numericCode: 710,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e5c",
        englishShortName: "South Georgia and the South Sandwich Islands",
        alpha2Code: "GS",
        alpha3Code: "SGS",
        numericCode: 239,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e5d",
        englishShortName: "South Sudan",
        alpha2Code: "SS",
        alpha3Code: "SSD",
        numericCode: 728,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e5e",
        englishShortName: "Spain",
        alpha2Code: "ES",
        alpha3Code: "ESP",
        numericCode: 724,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e5f",
        englishShortName: "Sri Lanka",
        alpha2Code: "LK",
        alpha3Code: "LKA",
        numericCode: 144,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e60",
        englishShortName: "Sudan (the)",
        alpha2Code: "SD",
        alpha3Code: "SDN",
        numericCode: 729,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e61",
        englishShortName: "Suriname",
        alpha2Code: "SR",
        alpha3Code: "SUR",
        numericCode: 740,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e62",
        englishShortName: "Svalbard and Jan Mayen",
        alpha2Code: "SJ",
        alpha3Code: "SJM",
        numericCode: 744,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e63",
        englishShortName: "Sweden",
        alpha2Code: "SE",
        alpha3Code: "SWE",
        numericCode: 752,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e64",
        englishShortName: "Switzerland",
        alpha2Code: "CH",
        alpha3Code: "CHE",
        numericCode: 756,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e65",
        englishShortName: "Syrian Arab Republic (the)",
        alpha2Code: "SY",
        alpha3Code: "SYR",
        numericCode: 760,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e66",
        englishShortName: "Taiwan (Province of China)",
        alpha2Code: "TW",
        alpha3Code: "TWN",
        numericCode: 158,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e67",
        englishShortName: "Tajikistan",
        alpha2Code: "TJ",
        alpha3Code: "TJK",
        numericCode: 762,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e68",
        englishShortName: "Tanzania, the United Republic of",
        alpha2Code: "TZ",
        alpha3Code: "TZA",
        numericCode: 834,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e69",
        englishShortName: "Thailand",
        alpha2Code: "TH",
        alpha3Code: "THA",
        numericCode: 764,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e6a",
        englishShortName: "Timor-Leste",
        alpha2Code: "TL",
        alpha3Code: "TLS",
        numericCode: 626,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e6b",
        englishShortName: "Togo",
        alpha2Code: "TG",
        alpha3Code: "TGO",
        numericCode: 768,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e6c",
        englishShortName: "Tokelau",
        alpha2Code: "TK",
        alpha3Code: "TKL",
        numericCode: 772,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e6d",
        englishShortName: "Tonga",
        alpha2Code: "TO",
        alpha3Code: "TON",
        numericCode: 776,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e6e",
        englishShortName: "Trinidad and Tobago",
        alpha2Code: "TT",
        alpha3Code: "TTO",
        numericCode: 780,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e6f",
        englishShortName: "Tunisia",
        alpha2Code: "TN",
        alpha3Code: "TUN",
        numericCode: 788,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e70",
        englishShortName: "Turkey",
        alpha2Code: "TR",
        alpha3Code: "TUR",
        numericCode: 792,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e71",
        englishShortName: "Turkmenistan",
        alpha2Code: "TM",
        alpha3Code: "TKM",
        numericCode: 795,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e72",
        englishShortName: "Turks and Caicos Islands (the)",
        alpha2Code: "TC",
        alpha3Code: "TCA",
        numericCode: 796,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e73",
        englishShortName: "Tuvalu",
        alpha2Code: "TV",
        alpha3Code: "TUV",
        numericCode: 798,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e74",
        englishShortName: "Uganda",
        alpha2Code: "UG",
        alpha3Code: "UGA",
        numericCode: 800,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e75",
        englishShortName: "Ukraine",
        alpha2Code: "UA",
        alpha3Code: "UKR",
        numericCode: 804,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e76",
        englishShortName: "United Arab Emirates (the)",
        alpha2Code: "AE",
        alpha3Code: "ARE",
        numericCode: 784,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e77",
        englishShortName:
          "United Kingdom of Great Britain and Northern Ireland (the)",
        alpha2Code: "GB",
        alpha3Code: "GBR",
        numericCode: 826,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e78",
        englishShortName: "United States Minor Outlying Islands (the)",
        alpha2Code: "UM",
        alpha3Code: "UMI",
        numericCode: 581,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e79",
        englishShortName: "United States of America (the)",
        alpha2Code: "US",
        alpha3Code: "USA",
        numericCode: 840,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e7a",
        englishShortName: "Uruguay",
        alpha2Code: "UY",
        alpha3Code: "URY",
        numericCode: 858,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.333Z",
        updatedAt: "2021-09-20T10:28:11.333Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e7b",
        englishShortName: "Uzbekistan",
        alpha2Code: "UZ",
        alpha3Code: "UZB",
        numericCode: 860,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e7c",
        englishShortName: "Vanuatu",
        alpha2Code: "VU",
        alpha3Code: "VUT",
        numericCode: 548,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e7d",
        englishShortName: "Venezuela (Bolivarian Republic of)",
        alpha2Code: "VE",
        alpha3Code: "VEN",
        numericCode: 862,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e7e",
        englishShortName: "Viet Nam",
        alpha2Code: "VN",
        alpha3Code: "VNM",
        numericCode: 704,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e7f",
        englishShortName: "Virgin Islands (British)",
        alpha2Code: "VG",
        alpha3Code: "VGB",
        numericCode: 92,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e80",
        englishShortName: "Virgin Islands (U.S.)",
        alpha2Code: "VI",
        alpha3Code: "VIR",
        numericCode: 850,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e81",
        englishShortName: "Wallis and Futuna",
        alpha2Code: "WF",
        alpha3Code: "WLF",
        numericCode: 876,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e82",
        englishShortName: "Western Sahara*",
        alpha2Code: "EH",
        alpha3Code: "ESH",
        numericCode: 732,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e83",
        englishShortName: "Yemen",
        alpha2Code: "YE",
        alpha3Code: "YEM",
        numericCode: 887,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e84",
        englishShortName: "Zambia",
        alpha2Code: "ZM",
        alpha3Code: "ZMB",
        numericCode: 894,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e85",
        englishShortName: "Zimbabwe",
        alpha2Code: "ZW",
        alpha3Code: "ZWE",
        numericCode: 716,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
      {
        _id: "6148623b04eb7ba0ca880e86",
        englishShortName: "Åland Islands",
        alpha2Code: "AX",
        alpha3Code: "ALA",
        numericCode: 248,
        isDeleted: false,
        deletedAt: false,
        createdBy: "1",
        createdAt: "2021-09-20T10:28:11.334Z",
        updatedAt: "2021-09-20T10:28:11.334Z",
        __v: 0,
      },
    ],
  },
  mutations: {
    SET_COUNTRIES(state, payload) {
      state.countries = payload;
    },
  },
  actions: {
    async getCountries({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getCountries",
          },
          { root: true }
        );
        const response = await api.get("countries");

        commit("SET_COUNTRIES", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getCountries",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getCountries",
            error: error,
          },
          { root: true }
        );
      }
    },

    registerInstitutesForMeetup({ commit, getters }, payload) {
      commit("setLoading", true);
      const institute = getters.institute;
    },

    logout({ commit }) {
      firebase.auth().signOut();
      commit("setInstitutes", null);
    },
  },
  getters: {
    countries(state) {
      return state.countries;
    },
  },
};
