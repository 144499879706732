<template>
  <v-dialog v-model="formVisibility" persistent max-width="720px">
    <v-card color="" class="mx-auto" flat max-width="720px">
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <span class="text-h6">DELETE ACCOUNT</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="12" md="5">
          <!-- <v-card color="" class="mx-auto" flat> -->
          <div class="text-center login-image mt-4">
            <img
              :src="require('@/assets/accountdelete2.gif')"
              alt="logo"
              width="240"
            />
          </div>
          <!-- </v-card> -->
        </v-col>
        <v-col cols="12" md="7">
          <div class="form-container pt-6">
            <v-card flat max-width="400px" min-height="320" class="mx-auto">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-alert
                    prominent
                    type="success"
                    class="mx-2 my-2"
                    :value="
                      deleteUserAccountApiActionStatus.status == 'success'
                    "
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <p class="text-capitalize">
                          account deleted successfully
                        </p>
                      </v-col>
                      <v-col class="shrink"> </v-col>
                    </v-row>
                  </v-alert>
                  <v-alert
                    prominent
                    type="error"
                    class="mx-2 my-2"
                    :value="deleteUserAccountApiActionStatus.status == 'failed'"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <p class="text-capitalize">
                          {{ deleteUserAccountApiActionStatus.error.message }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <v-chip
                      class="ma-2 mb-6"
                      color="#8269B2"
                      label
                      small
                      outlined
                      @click="showQuickHelpDialog()"
                    >
                      <v-icon left size="14"> mdi-help-circle-outline </v-icon>
                      HELP
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="#F92B60"
                    class="pa-5 white--text"
                    block
                    ripple
                    depressed
                    :disabled="isDeleteButtonDisabled"
                    :loading="deleteUserAccountApiActionStatus.isActive"
                    @click="deleteUserAccount()"
                  >
                    DELETE ACCOUNT
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="#671cc9"
                    class="pa-5 my-4 white--text"
                    block
                    ripple
                    depressed
                    @click="close()"
                  >
                    CLOSE
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({
    isDeleteButtonDisabled: false,
    isDeleteSuccess: false,
  }),
  computed: {
    formVisibility() {
      return this.$store.getters["users/deleteUserAccountDialogVisibility"];
    },

    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    authTeacher() {
      return this.$store.getters["auth/authTeacher"];
    },

    deleteUserAccountApiActionStatus() {
      const status =
        this.$store.getters["app/apiActionByName"]("deleteUserAccount");

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        error: { status: "", message: "" },
        isActive: false,
        isRed: false,
      };
    },
  },
  watch: {
    deleteUserAccountApiActionStatus: {
      handler(newValue, oldValue) {
        if (newValue.status == "success") {
          this.isDeleteButtonDisabled = true;
          this.isDeleteSuccess = true;
        }
      },
    },
  },
  methods: {
    deleteUserAccount() {
      const payload = {
        teacherId: this.authTeacher._id,
        userId: this.authUser._id,
      };
      console.log(payload);
      this.$store.dispatch("users/deleteUserAccount", payload);
    },

    close() {
      this.removeDeleteUserAccountApiAction();
      this.$store.dispatch("users/setDeleteUserAccountDialogVisibility", false);
      if (this.isDeleteSuccess) {
        this.$router.push({
          name: "Home",
        });
      }
      this.isDeleteButtonDisabled = false;
      this.isDeleteSuccess = false;
    },

    removeDeleteUserAccountApiAction() {
      this.$store.dispatch("app/removeApiAction", "deleteUserAccount");
    },

    showQuickHelpDialog() {
      const content = {
        mainTitle: "Delete Account",
        messages: [
          {
            title: "Delete Account",
            message: "once yout delete the account it cannot be restored",
          },
        ],
      };
      this.$store.dispatch("app/setQuickHelpDialogContent", content);
      this.$store.dispatch("app/setQuickHelpDialogVisibility", true);
    },
  },
};
</script>
