<template>
  <v-dialog v-model="formVisibility" persistent max-width="740px">
    <v-card color="" class="mx-auto" flat max-width="740px">
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <span class="text-h6">UPDATE INFO</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <!-- <v-card color="" class="mx-auto" flat> -->
          <div class="text-center login-image">
            <img
              :src="require('@/assets/userInfo.gif')"
              alt="logo"
              width="240"
            />
          </div>
          <!-- </v-card> -->
        </v-col>
        <v-col cols="12" md="8">
          <validation-observer
            ref="basicInfoObserver"
            v-slot="{ invalid, handleSubmit }"
          >
            <v-form :disabled="false">
              <v-card flat max-width="440px" class="mx-auto">
                <v-row no-gutters>
                  <v-col cols="12">
                    <validation-provider
                      name="Name"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="name"
                        label="NAME"
                        hint="Enter Name"
                        prepend-inner-icon="mdi-at"
                        dense
                        outlined
                        required
                        counter
                        rows="2"
                        class="mx-2"
                        :success="valid"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12">
                    <validation-provider
                      name="Gender"
                      rules=""
                      v-slot="{ errors, valid }"
                    >
                      <v-autocomplete
                        chips
                        :items="availableGenders"
                        v-model="gender"
                        hint="Select Your Gender"
                        :error-messages="errors[0]"
                        label="GENDER"
                        prepend-inner-icon="mdi-store-marker-outline"
                        dense
                        outlined
                        required
                        small-chips
                        :success="valid"
                        class="mx-2"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>

                  <!-- Alert  -->
                  <v-col cols="12">
                    <v-alert
                      prominent
                      type="success"
                      class="mx-2 mb-6"
                      :value="
                        updateUserBasicInfoApiActionStatus.status == 'success'
                      "
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <p class="text-capitalize">updated successfully</p>
                        </v-col>
                        <v-col class="shrink"> </v-col>
                      </v-row>
                    </v-alert>
                    <v-alert
                      prominent
                      type="error"
                      class="mx-2 mb-6"
                      :value="
                        updateUserBasicInfoApiActionStatus.status == 'failed'
                      "
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <p class="text-capitalize">
                            {{
                              updateUserBasicInfoApiActionStatus.error.message
                            }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>

                  <!-- Help -->
                  <v-col cols="12">
                    <div class="text-right">
                      <v-chip
                        class="ma-4"
                        color="#8269B2"
                        label
                        small
                        outlined
                        @click="showQuickHelpDialog()"
                      >
                        <v-icon left size="14">
                          mdi-help-circle-outline
                        </v-icon>
                        HELP
                      </v-chip>
                    </div>
                  </v-col>

                  <!-- Buttons -->
                  <v-col cols="12">
                    <v-btn
                      color="#671cc9"
                      class="white--text"
                      block
                      ripple
                      depressed
                      :loading="updateUserBasicInfoApiActionStatusIsActive"
                      :disabled="isUpdateButtonDisabled"
                      @click="handleSubmit(updateUserBasicInfo)"
                    >
                      UPDATE
                    </v-btn>
                  </v-col>

                  <v-col cols="12">
                    <div class="text-right my-4">
                      <v-btn
                        color="#F92B60"
                        class="white--text"
                        block
                        ripple
                        depressed
                        :disabled="false"
                        @click="close()"
                      >
                        CLOSE
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </validation-observer>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({
    name: "",
    gender: "",

    availableGenders: ["MALE", "FEMALE", "OTHER"],

    isFormDisabled: false,

    updateUserBasicInfoApiActionStatusIsActive: false,

    isUpdateButtonDisabled: false,

    cancelButtonText: "CLOSE",
  }),
  computed: {
    formVisibility() {
      return this.$store.getters["users/updateUserBasicInfoDialogVisibility"];
    },
    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    updateUserBasicInfoApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"](
        "updateUserBasicInfo"
      );

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        error: { status: "", message: "" },
        isActive: false,
        isRed: false,
      };
    },
  },
  watch: {
    updateUserBasicInfoApiActionStatus: {
      handler(newValue, oldValue) {
        this.isFormDisabled = newValue.isActive;
        this.updateUserBasicInfoApiActionStatusIsActive = newValue.isActive;
        this.isUpdateButtonDisabled = newValue.isActive;

        if (newValue.status == "success") {
          this.isUpdateButtonDisabled = true;
          this.isFormDisabled = true;
        }
      },
    },

    authUser: {
      handler(newValue, oldValue) {
        this.name = "";
        this.gender = "";

        if (newValue.name) {
          this.name = newValue.name;
        }

        if (newValue.gender) {
          this.gender = newValue.gender.toUpperCase();
        }
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      this.resetFormVariables();
    },

    updateUserBasicInfo() {
      this.isFormDisabled = true;
      const payload = {
        name: this.name,
        gender: this.gender,
        userId: this.authUser._id,
      };
      this.$store.dispatch("users/updateUserBasicInfo", payload);
    },

    close() {
      this.resetValidationErrors();

      this.removeUpdateUserBasicInfoApiAction();

      this.$store.dispatch(
        "users/setUpdateUserBasicInfoDialogVisibility",
        false
      );
    },

    resetFormVariables() {
      this.updateUserBasicInfoApiActionStatusIsActive = false;
      this.isFormDisabled = false;
    },

    removeUpdateUserBasicInfoApiAction() {
      this.$store.dispatch("app/removeApiAction", "updateUserBasicInfo");
    },

    resetValidationErrors() {
      requestAnimationFrame(() => {
        this.$refs.basicInfoObserver.reset();
      });
    },

    showQuickHelpDialog() {
      const content = {
        mainTitle: "Update Basic Info",
        messages: [
          {
            title: "Update",
            message: "Provide your name and gender",
          },
        ],
      };
      this.$store.dispatch("app/setQuickHelpDialogContent", content);
      this.$store.dispatch("app/setQuickHelpDialogVisibility", true);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
