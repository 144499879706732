import api from "@/api";

export default {
  namespaced: true,
  state: {
    institutes: [],
    institute: {
      _id: "",
      name: "",
      code: "",
      createdBy: "",
    },
    addInstituteDialogVisibility: false,
  },
  mutations: {
    SET_INSTITUTES(state, payload) {
      state.institutes = payload;
    },

    ADD_INSTITUTE_TO_INSTITUTES(state, payload) {
      const institutes = [payload, ...state.institutes];
      state.institutes = institutes;
    },

    SET_ADD_INSTITUTE_DIALOG_VISIBILITY(state, payload) {
      state.addInstituteDialogVisibility = payload;
    },
  },
  actions: {
    async asyncGetInstitutes({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getInstitutes",
          },
          { root: true }
        );
        const response = await api.get("institutes");

        commit("SET_INSTITUTES", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getInstitutes",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getInstitutes",
            error: error,
          },
          { root: true }
        );
      }
    },

    getInstitutes({ commit }) {
      commit(
        "app/SET_API_ACTION_STATUS_PENDING",
        {
          name: "getInstitutes",
        },
        { root: true }
      );

      api
        .get("institutes")
        .then((response) => {
          commit("SET_INSTITUTES", response.data.data);
          commit(
            "app/SET_API_ACTION_STATUS_SUCCESS",
            {
              name: "getInstitutes",
            },
            { root: true }
          );
        })
        .catch((error) => {
          let responseError = {
            status: "500",
            message: "server error",
          };

          if (error.response) {
            responseError = error.response.data.error;
          }
          commit(
            "app/SET_API_ACTION_STATUS_FAILED",
            {
              name: "getInstitutes",
              error: responseError,
            },
            { root: true }
          );
        });
    },

    async createInstitute({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createInstitute",
          },
          { root: true }
        );

        const response = await api.post("institutes", payload);

        commit("ADD_INSTITUTE_TO_INSTITUTES", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createInstitute",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createInstitute",
            error: error,
          },
          { root: true }
        );
      }
    },

    setAddInstituteDialogVisibility({ commit }, payload) {
      commit("SET_ADD_INSTITUTE_DIALOG_VISIBILITY", payload);
    },
  },
  getters: {
    institutes(state) {
      return state.institutes;
    },
    addInstituteDialogVisibility(state) {
      return state.addInstituteDialogVisibility;
    },
  },
};
