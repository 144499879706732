<template>
  <v-container>
    <v-row class="text-center mb-6">
      <v-col cols="12">
        <pie-chart></pie-chart>
      </v-col>
    </v-row>
    <v-row class="text-center mt-6">
      <v-col cols="12">
        <bar-chart></bar-chart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PieChart from "@/components/home/PieChart.vue";
import BarChart from "@/components/home/BarChart.vue";

export default {
  name: "HelloWorld",
  components: {
    PieChart,
    BarChart,
  },
  data: () => ({}),
};
</script>
