import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import app from "./modules/app";

import auth from "./modules/auth";

import users from "./modules/users";

import teachers from "./modules/teachers";

import students from "./modules/students";

import classes from "./modules/classes";

import mou from "./modules/mou";
import mous from "./modules/mous";

import university from "./modules/university";
import universities from "./modules/universities";

import institute from "./modules/institute";
import institutes from "./modules/institutes";

import country from "./modules/country";
import countries from "./modules/countries";

import exams from "./modules/exams";

import subjects from "./modules/subjects";

export default new Vuex.Store({
  modules: {
    app,
    auth,
    users,
    teachers,
    students,
    exams,
    subjects,
    classes,
    mou,
    mous,
    university,
    universities,
    institute,
    institutes,
    country,
    countries,
  },
});
