<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="left_drawer"
      clipped
      app
      disable-resize-watcher
    >
      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to">
          <v-list-item-icon>
            <v-icon size="18px">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="right_drawer"
      app
      clipped
      right
      disable-resize-watcher
    >
      <template v-if="authUser._id">
        <v-list-item>
          <v-list-item-content class="text-center">
            <v-list-item-title class="title mt-6">
              <v-avatar size="78" v-if="authUser.profilePictureUrl">
                <img
                  :src="serverUrl + authUser.profilePictureUrl"
                  :alt="authUser.name"
                />
              </v-avatar>
              <v-avatar size="78" v-else>
                <img
                  :src="require('./assets/avatar.png')"
                  :alt="authUser.name"
                />
              </v-avatar>
              <div class="text-center">
                <v-btn icon @click="showUpdateUserProfilePictureDialog()">
                  <v-icon small>mdi-camera-plus-outline</v-icon>
                </v-btn>
              </div>
            </v-list-item-title>
            <v-list-item-subtitle class="my-2 text-uppercase">
              {{ authUser.name }}
              <br />
              <v-btn icon @click="showUpdateUserBasicInfoDialog()">
                <v-icon small>mdi-square-edit-outline</v-icon>
              </v-btn>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="">
              <v-chip
                v-if="authUserType == 'administrator'"
                class=""
                color="#F92B60"
                label
                text-color="white"
                small
              >
                <v-icon left small> mdi-shield-crown-outline </v-icon>
                ADMINISTRATOR
              </v-chip>
              <v-chip
                v-if="authUserType == 'teacher'"
                class=""
                color="pink"
                label
                text-color="white"
                small
              >
                <v-icon left small> mdi-human-male-board </v-icon>
                TEACHER
              </v-chip>
              <v-chip
                v-if="authUserType == 'student'"
                class=""
                color="#93E088"
                label
                text-color="white"
                small
              >
                <v-icon left small> mdi-account-school-outline </v-icon>
                STUDENT
              </v-chip>
              <!-- <v-select
                :items="roles"
                label="ROLE"
                v-model="authUserType"
              ></v-select> -->
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-row no-gutters align="center" justify="center">
          <v-col cols="12" md="12" class="pa-2">
            <v-card :loading="false" class="mx-auto" max-width="320" flat>
              <div class="text-center text-caption text-uppercase">
                CONTACT INFO
                <v-btn icon @click="showUpdateUserContactInfoDialog()">
                  <v-icon small>mdi-square-edit-outline</v-icon>
                </v-btn>
              </div>
              <div
                class="text-left text-caption text-uppercase ml-4"
                v-if="authUser.phone"
              >
                <v-icon aria-hidden="false" small class="mr-2">
                  mdi-phone
                </v-icon>
                0{{ authUser.phone }}
              </div>

              <div class="text-left text-caption text-uppercase ml-4" v-else>
                <v-icon aria-hidden="false" small class="mr-2">
                  mdi-phone
                </v-icon>
                NO PHONE
              </div>
              <div
                class="text-left text-caption text-lowercase ml-4"
                v-if="authUser.email"
              >
                <v-icon aria-hidden="false" small class="mr-2">
                  mdi-mail
                </v-icon>
                {{ authUser.email }}
              </div>

              <div class="text-left text-caption text-uppercase ml-4" v-else>
                <v-icon aria-hidden="false" small class="mr-2">
                  mdi-email
                </v-icon>
                NO EMAIL
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <v-list-item v-else>
        <v-list-item-content class="text-center">
          <v-list-item-title class="title mt-6">
            <v-avatar size="78">
              <img :src="require('./assets/avatar.png')" />
            </v-avatar>
          </v-list-item-title>

          <v-list-item-subtitle class="">
            <v-btn block color="#671cc9" dark depressed @click="goToAuth()">
              LOGIN
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <template v-slot:append v-if="authUser._id">
        <div class="pa-4">
          <v-btn block color="#fc1040" dark depressed @click="logout()">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      flat
      clipped-right
      clipped-left
      height="58px"
      color="#FFFFFF"
      class="app-bar"
    >
      <v-app-bar-nav-icon
        @click="left_drawer = !left_drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-avatar size="28" class="mr-2">
          <img :src="require('./assets/logo.svg')" alt="classclass.lk" />
        </v-avatar>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="right_drawer = !right_drawer">
        <v-avatar size="28" v-if="authUser.profilePictureUrl">
          <img
            :src="'http://localhost:6060/' + authUser.profilePictureUrl"
            :alt="authUser.name"
          />
        </v-avatar>
        <v-avatar size="28" v-else>
          <img
            :src="require('./assets/avatar.png')"
            :alt="authUser.name || ''"
          />
        </v-avatar>
      </v-btn>
    </v-app-bar>

    <v-main class="app-container">
      <v-container class="main-container">
        <router-view></router-view>
      </v-container>
    </v-main>
    <send-sms-dialog></send-sms-dialog>
    <quick-help-dialog></quick-help-dialog>
    <bottom-navigation></bottom-navigation>
    <update-user-basic-info-dialog></update-user-basic-info-dialog>
    <update-user-contact-info-dialog></update-user-contact-info-dialog>
    <update-user-profile-picture-dialog></update-user-profile-picture-dialog>
    <delete-user-account-dialog></delete-user-account-dialog>
    <!-- by admin -->
    <delete-class-dialog></delete-class-dialog>
  </v-app>
</template>

<script>
import BottomNavigation from "@/components/app/BottomNavigation.vue";
import QuickHelpDialog from "@/components/app/quickHelpDialog.vue";

import DeleteUserAccountDialog from "@/components/users/dialogs/DeleteUserAccountDialog.vue";

import DeleteClassDialog from "@/components/classes/dialogs/DeleteClassDialog.vue";

import UpdateUserContactInfoDialog from "@/components/users/dialogs/UpdateUserContactInfoDialog.vue";

import UpdateUserBasicInfoDialog from "@/components/users/dialogs/UpdateUserBasicInfoDialog.vue";

import UpdateUserProfilePictureDialog from "@/components/users/dialogs/UpdateUserProfilePictureDialog.vue";

import sendSmsDialog from "@/components/users/dialogs/sendSmsDialog.vue";

export default {
  components: {
    BottomNavigation,
    QuickHelpDialog,
    DeleteClassDialog,
    DeleteUserAccountDialog,
    UpdateUserContactInfoDialog,
    UpdateUserBasicInfoDialog,
    UpdateUserProfilePictureDialog,
    sendSmsDialog,
  },
  data: () => ({
    left_drawer: false,
    right_drawer: false,
    value: 1,
    items: [
      { title: "HOME", icon: "mdi-home-outline", to: "/" },
      { title: "CLASSES", icon: "mdi-notebook-edit-outline", to: "/classes" },
      {
        title: "TEACHERS",
        icon: "mdi-human-male-board",
        to: "/teachers",
      },
      { title: "EXAMS", icon: "mdi-account-group-outline", to: "/exams" },
      { title: "SUBJECTS", icon: "mdi-movie-star-outline", to: "/subjects" },
      { title: "USERS", icon: "mdi-account-group-outline", to: "/users" },
      { title: "LOGIN", icon: "mdi-shield-lock-open-outline", to: "/auth" },
    ],
    roles: ["administrator", "teacher", "student", "guest"],
  }),
  watch: {
    authUser: {
      handler(newValue, oldValue) {
        if (newValue._id && newValue.type == "teacher") {
          this.$store.dispatch("auth/resetAuthUserStudent");
          this.$store.dispatch("teachers/getAuthTeacher", newValue._id);
        }
        if (newValue._id && newValue.type == "student") {
          this.$store.dispatch("auth/resetAuthUserTeacher");
          this.$store.dispatch("students/getAuthStudent", newValue._id);
        }
        if (newValue._id && newValue.type == "administrator") {
          this.$store.dispatch("auth/resetAuthUserTeacher");
          this.$store.dispatch("auth/resetAuthUserStudent");
        }
      },
      immediate: true,
    },
  },

  computed: {
    authUser() {
      const user = this.$store.getters["auth/authUser"];
      if (user._id) {
        return user;
      }
      return {
        _id: null,
        role: "",
        name: "",
        username: "",
        profilePictureUrl: "",
        type: "",
        phone: "",
        email: "",
      };
    },

    authUserType: {
      get: function () {
        if (this.authUser && this.authUser.type) {
          return this.authUser.type[0];
        }
        return "";
      },
      set: function (newValue) {
        this.$store.dispatch("auth/setAuthUserType", newValue);
      },
    },

    quickHelpDialog() {
      const quickHelpDialog = this.$store.getters["app/quickHelpDialog"];
      if (quickHelpDialog) {
        return quickHelpDialog;
      } else {
        return {
          visibility: false,
          title: "",
          message: "",
        };
      }
    },

    serverUrl() {
      const serverUrl = this.$store.getters["app/serverUrl"];
      if (!serverUrl) {
        return "http://localhost:6060/";
      }
      return serverUrl;
    },
  },
  methods: {
    showUpdateUserBasicInfoDialog() {
      this.$store.dispatch(
        "users/setUpdateUserBasicInfoDialogVisibility",
        true
      );
    },
    showUpdateUserContactInfoDialog() {
      this.$store.dispatch(
        "users/setUpdateUserContactInfoDialogVisibility",
        true
      );
    },
    showUpdateUserProfilePictureDialog() {
      this.$store.dispatch(
        "users/setUpdateUserProfilePictureDialogVisibility",
        true
      );
    },
    goToAuth() {
      this.$router.push({
        name: "Auth",
      });
    },

    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
  created: function () {
    this.$store.dispatch("users/getHome");
    this.$store.dispatch("mous/getMous");
  },
};
</script>

<style scoped>
.app-bar {
  /* border-bottom: 1px solid rgb(187, 187, 187) !important; */
  box-shadow: 0 2px 8px #ececec !important;
}

.border {
  border: 1px solid #ebedf0;
}

.bottom-navigation {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-bottom: 64px;
}

/* * {
  font-family: "Share Tech Mono", monospace;
} */

/* * {
  font-family: "Montserrat", sans-serif;
} */
.error {
  background-color: #ff1545 !important;
  border-color: #fc1040 !important;
}

.main-container {
  width: 100%;
  max-width: 1120px !important;
  margin: 0 auto;
}
</style>
