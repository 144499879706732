export default {
  namespaced: true,
  state: {
    institutes: [
      {
        id: 1,
        name: "Student Village",
      },
      {
        id: 2,
        name: "Idea Engine",
      },
    ],
  },
  mutations: {
    setInstitutes(state, payload) {
      state.institute = payload;
    },
  },
  actions: {
    registerInstitutesForMeetup({ commit, getters }, payload) {
      commit("setLoading", true);
      const institute = getters.institute;
    },

    logout({ commit }) {
      firebase.auth().signOut();
      commit("setInstitutes", null);
    },
  },
  getters: {
    institutes(state) {
      return state.institutes;
    },
  },
};
