import api from "@/api";

export default {
  namespaced: true,
  state: {
    students: [],
    student: {
      _id: "",
    },
    createStudentDialogVisibility: false,
    updateStudentDialogVisibility: false,
    updateStudentDescriptionDialogVisibility: false,
    deleteStudentDialogVisibility: false,
  },
  mutations: {
    SET_STUDENTS(state, payload) {
      state.students = payload;
    },

    SET_STUDENT(state, payload) {
      state.student = payload;
    },

    ADD_STUDENT_TO_STUDENTS(state, payload) {
      const students = [payload, ...state.students];
      state.students = students;
    },

    SET_CREATE_STUDENT_DIALOG_VISIBILITY(state, payload) {
      state.createStudentDialogVisibility = payload;
    },

    SET_DELETE_STUDENT_DIALOG_VISIBILITY(state, payload) {
      state.deleteStudentDialogVisibility = payload;
    },

    SET_UPDATE_STUDENT_DIALOG_VISIBILITY(state, payload) {
      state.updateStudentDialogVisibility = payload;
    },

    SET_UPDATE_STUDENT_DESCRIPTION_DIALOG_VISIBILITY(state, payload) {
      state.updateStudentDescriptionDialogVisibility = payload;
    },
  },
  actions: {
    /**
     *
     * @param {*} param0
     */
    async getStudents({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getStudents",
          },
          { root: true }
        );
        const response = await api.get("students");

        commit("SET_STUDENTS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getStudents",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getStudents",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload studentId
     */
    async getStudentById({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getStudentById",
          },
          { root: true }
        );
        const response = await api.get(`students/${payload}`);

        commit("SET_STUDENT", response.data.data);

        // it's better to handle status=false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getStudentById",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getStudentById",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload studentId
     */
    async getStudentByUserId({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getStudentById",
          },
          { root: true }
        );
        const response = await api.get(`students/userid/${payload}`);

        commit("SET_STUDENT", response.data.data);

        // it's better to handle status=false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getStudentById",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getStudentById",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload userId
     */
    async getAuthStudent({ commit }, userId) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getAuthStudent",
          },
          { root: true }
        );
        const response = await api.get(`students/auth-user/${userId}`);

        commit("auth/SET_AUTH_USER_STUDENT_DATA", response.data.data, {
          root: true,
        });

        // it's better to handle status=false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getAuthStudent",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getAuthStudent",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {name, code, createdBy}
     */
    async createStudent({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createStudent",
          },
          { root: true }
        );

        const response = await api.post("students", payload);

        commit("ADD_STUDENT_TO_STUDENTS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createStudent",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createStudent",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {studentId}
     */
    async deleteStudent({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "deleteStudent",
          },
          { root: true }
        );

        const response = await api.delete(`students/${payload.studentId}`);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "deleteStudent",
          },
          { root: true }
        );

     
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "deleteStudent",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    setCreateStudentDialogVisibility({ commit }, payload) {
      commit("SET_CREATE_STUDENT_DIALOG_VISIBILITY", payload);
    },

    setUpdateStudentDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_STUDENT_DIALOG_VISIBILITY", payload);
    },

    setUpdateStudentDescriptionDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_STUDENT_DESCRIPTION_DIALOG_VISIBILITY", payload);
    },

    setDeleteStudentDialogVisibility({ commit }, payload) {
      commit("SET_DELETE_STUDENT_DIALOG_VISIBILITY", payload);
    },
  },

  getters: {
    students(state) {
      return state.students;
    },

    student(state) {
      return state.student;
    },

    createStudentDialogVisibility(state) {
      return state.createStudentDialogVisibility;
    },

    updateStudentDialogVisibility(state) {
      return state.updateStudentDialogVisibility;
    },

    updateStudentDescriptionDialogVisibility(state) {
      return state.updateStudentDescriptionDialogVisibility;
    },

    deleteStudentDialogVisibility(state) {
      return state.deleteStudentDialogVisibility;
    },
  },
};
