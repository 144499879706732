<template>
  <v-dialog v-model="formVisibility" persistent max-width="740px">
    <v-card color="" class="mx-auto" flat max-width="740px">
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <span class="text-h6">UPDATE PROFILE PICTURE</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <!-- <v-card color="" class="mx-auto" flat> -->
          <div
            class="text-center login-image"
            v-if="authUser.profilePictureUrl"
          >
            <img
              :src="serverUrl + authUser.profilePictureUrl"
              alt="logo"
              width="100"
            />
          </div>
          <div class="text-center login-image" v-else>
            <img :src="require('@/assets/avatar.png')" alt="logo" width="100" />
          </div>
          <!-- </v-card> -->
        </v-col>
        <v-col cols="12" md="8">
          <v-form ref="profilePictureForm" v-model="valid">
            <v-col cols="12">
              <v-file-input
                :rules="rules"
                v-model="picture"
                accept="image/png, image/jpeg, image/jpg, image/bmp"
                placeholder="Pick an avatar"
                prepend-icon=""
                outlined
                dense
                show-size
                :loading="updateUserProfilePictureApiActionStatusIsActive"
                prepend-inner-icon="mdi-camera"
                label="PROFILE PICTURE"
              ></v-file-input>
            </v-col>

            <!-- Alert  -->
            <v-col cols="12">
              <v-alert
                prominent
                type="success"
                class="mx-2 mb-2"
                :value="
                  updateUserProfilePictureApiActionStatus.status == 'success'
                "
              >
                <v-row align="center">
                  <v-col class="grow">
                    <p class="text-capitalize">updated successfully</p>
                  </v-col>
                  <v-col class="shrink"> </v-col>
                </v-row>
              </v-alert>
              <v-alert
                prominent
                type="error"
                class="mx-2 mb-6"
                :value="
                  updateUserProfilePictureApiActionStatus.status == 'failed'
                "
              >
                <v-row align="center">
                  <v-col class="grow">
                    <p class="text-capitalize">
                      {{
                        updateUserProfilePictureApiActionStatus.error.message
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>

            <!-- Help -->
            <v-col cols="12">
              <div class="text-right">
                <v-chip
                  class="mx-4"
                  color="#8269B2"
                  label
                  small
                  outlined
                  @click="showQuickHelpDialog()"
                >
                  <v-icon left size="14"> mdi-help-circle-outline </v-icon>
                  HELP
                </v-chip>
              </div>
            </v-col>

            <!-- Buttons -->
            <v-col cols="12">
              <v-btn
                color="#671cc9"
                class="white--text"
                block
                ripple
                depressed
                :loading="updateUserProfilePictureApiActionStatusIsActive"
                :disabled="isUpdateButtonDisabled"
                @click="updateUserProfilePicture"
              >
                UPDATE
              </v-btn>
            </v-col>

            <v-col cols="12">
              <div class="text-right mb-2">
                <v-btn
                  color="#F92B60"
                  class="white--text"
                  block
                  ripple
                  depressed
                  :disabled="false"
                  @click="close()"
                >
                  CLOSE
                </v-btn>
              </div>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({
    picture: null,
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
      (value) => !!value || "Image required",
    ],
    valid: true,

    isFormDisabled: false,

    updateUserProfilePictureApiActionStatusIsActive: false,

    isUpdateButtonDisabled: false,

    cancelButtonText: "CLOSE",
  }),
  computed: {
    serverUrl() {
      const serverUrl = this.$store.getters["app/serverUrl"];
      if (!serverUrl) {
        return "http://localhost:6060/";
      }
      return serverUrl;
    },

    formVisibility() {
      return this.$store.getters[
        "users/updateUserProfilePictureDialogVisibility"
      ];
    },
    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    updateUserProfilePictureApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"](
        "updateUserProfilePicture"
      );

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        error: { status: "", message: "" },
        isActive: false,
        isRed: false,
      };
    },
  },
  watch: {
    updateUserProfilePictureApiActionStatus: {
      handler(newValue, oldValue) {
        this.isFormDisabled = newValue.isActive;
        this.updateUserProfilePictureApiActionStatusIsActive =
          newValue.isActive;
        this.isUpdateButtonDisabled = newValue.isActive;

        if (newValue.status == "success") {
          this.isFormDisabled = true;
        }
      },
    },

    authUser: {
      handler(newValue, oldValue) {
        this.name = "";
        this.gender = "";

        if (newValue.name) {
          this.name = newValue.name;
        }

        if (newValue.gender) {
          this.gender = newValue.gender;
        }
      },
      immediate: true,
    },
  },
  methods: {
    resetForm() {
      this.$refs.profilePictureForm.reset();
    },

    resetFormValidation() {
      this.$refs.profilePictureForm.resetValidation();
    },

    init() {
      this.resetFormVariables();
    },

    updateUserProfilePicture() {
      this.isFormDisabled = true;
      const resutl = this.$refs.profilePictureForm.validate();
      const payload = {
        profilePicture: this.picture,
        userId: this.authUser._id,
      };
      if (resutl) {
        this.$store.dispatch("users/updateUserProfilePicture", payload);
      }
    },

    close() {
      this.resetForm();
      this.resetFormValidation();
      this.resetFormVariables();
      this.removeUpdateUserProfilePictureApiAction();

      this.$store.dispatch(
        "users/setUpdateUserProfilePictureDialogVisibility",
        false
      );
    },

    resetFormVariables() {
      this.updateUserProfilePictureApiActionStatusIsActive = false;
      this.isFormDisabled = false;
      this.picture = null;
      this.valid = true;
    },

    removeUpdateUserProfilePictureApiAction() {
      this.$store.dispatch("app/removeApiAction", "updateUserProfilePicture");
    },

    showQuickHelpDialog() {
      const content = {
        mainTitle: "Upload Profile Picture",
        messages: [
          {
            title: "Profile Picture",
            message: "Provide a profiel picture less than the size of 2MB.",
          },
        ],
      };
      this.$store.dispatch("app/setQuickHelpDialogContent", content);
      this.$store.dispatch("app/setQuickHelpDialogVisibility", true);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
