<template>
  <v-container class="my-border">
    <v-row no-gutters>
      <v-app-bar color="white" flat>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </v-row>
    <v-row no-gutters class="text-center">
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          class="ma-6"
          placeholder="REFFERENCE NUMBER"
          outlined
        ></v-text-field>
      </v-col>

      <home-view msg="Welcome to Your Vue.js App"> </home-view>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import HomeView from "@/components/home/HomeView.vue";

export default {
  name: "Home",
  components: {
    HomeView,
  },
  data: () => ({
    search: "",
    items: [
      {
        text: "HOME",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
  }),
};
</script>
