import api from "@/api";

export default {
  namespaced: true,

  state: () => ({
    user: {
      name: "",
      type: "",
      createdAt: "",
    },

    users: [],

    isUsernameAvailable: true,

    // Dialogs
    addUserDialogVisibility: false,

    // Update
    updateUserContactInfoDialogVisibility: false,
    updateUserBasicInfoDialogVisibility: false,
    updateUserProfilePictureDialogVisibility: false,

    deleteUserAccountDialogVisibility: false,
    deleteUserAccountByAdminDialogVisibility: false,

    sendSmsDialogVisibility: false,
  }),

  mutations: {
    SET_SEND_SMS_DIALOG_VISIBILITY(state, payload) {
      state.sendSmsDialogVisibility = payload;
    },

    SET_USERS(state, payload) {
      state.users = payload;
    },

    ADD_USER_TO_USERS(state, payload) {
      const users = [payload, ...state.users];
      state.users = users;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_ADD_USER_DIALOG_VISIBILITY(state, payload) {
      state.addUserDialogVisibility = payload;
    },

    SET_USER_NAME_AVAILABILITY(state, payload) {
      state.isUsernameAvailable = payload;
    },

    SET_UPDATE_USER_BASIC_INFO_DIALOG_VISIBILITY(state, payload) {
      state.updateUserBasicInfoDialogVisibility = payload;
    },

    SET_UPDATE_USER_CONTACT_INFO_DIALOG_VISIBILITY(state, payload) {
      state.updateUserContactInfoDialogVisibility = payload;
    },

    SET_UPDATE_USER_PROFILE_PICTURE_DIALOG_VISIBILITY(state, payload) {
      state.updateUserProfilePictureDialogVisibility = payload;
    },

    SET_DELETE_USER_ACCOUNT_DIALOG_VISIBILITY(state, payload) {
      state.deleteUserAccountDialogVisibility = payload;
    },

    SET_DELETE_USER_ACCOUNT_BY_ADMIN_DIALOG_VISIBILITY(state, payload) {
      state.deleteUserAccountByAdminDialogVisibility = payload;
    },
  },

  actions: {
    async getUsers({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getUsers",
          },
          { root: true }
        );
        const response = await api.get("users");
        commit("SET_USERS", response.data.data);
        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getUsers",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getUsers",
            error: error,
          },
          { root: true }
        );
      }
    },

    async createUser({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createUser",
          },
          { root: true }
        );

        const response = await api.post("auth/register", payload);

        console.log(response.data.data);

        commit("ADD_USER_TO_USERS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createUser",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createUser",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    async getUserById({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getUserById",
          },
          { root: true }
        );
        console.log(payload);

        const response = await api.get(`users/${payload}`);

        commit("SET_USER", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getUserById",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getUserById",
            error: error,
          },
          { root: true }
        );
      }
    },

    setSendSmsDialogVisibility({ commit }, payload) {
      commit("SET_SEND_SMS_DIALOG_VISIBILITY", payload);
    },

    setAddUserDialogVisibility({ commit }, payload) {
      commit("SET_ADD_USER_DIALOG_VISIBILITY", payload);
    },

    setUpdateUserContactInfoDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_USER_CONTACT_INFO_DIALOG_VISIBILITY", payload);
    },

    setUpdateUserBasicInfoDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_USER_BASIC_INFO_DIALOG_VISIBILITY", payload);
    },

    setUpdateUserProfilePictureDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_USER_PROFILE_PICTURE_DIALOG_VISIBILITY", payload);
    },

    setDeleteUserAccountDialogVisibility({ commit }, payload) {
      commit("SET_DELETE_USER_ACCOUNT_DIALOG_VISIBILITY", payload);
    },

    setDeleteUserAccountByAdminDialogVisibility({ commit }, payload) {
      commit("SET_DELETE_USER_ACCOUNT_BY_ADMIN_DIALOG_VISIBILITY", payload);
    },

    async getHome({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getHome",
          },
          { root: true }
        );
        const response = await api.get("/");
        console.log(response.data.data);
        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getHome",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getHome",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    async getUsernameAvailability({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getUsernameAvailability",
          },
          { root: true }
        );
        const response = await api.get(
          `users/username-availability/${payload}`
        );

        if (response.data.data.isAvailable) {
          commit("SET_USER_NAME_AVAILABILITY", true);
        } else {
          commit("SET_USER_NAME_AVAILABILITY", false);
        }

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getUsernameAvailability",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getUsernameAvailability",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {userId , email}
     */
    async updateUserEmail({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateUserEmail",
          },
          { root: true }
        );

        const response = await api.patch(
          `users/${payload.userId}/email`,
          payload
        );

        commit("auth/SET_AUTH_USER_DATA", response.data.data, {
          root: true,
        });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateUserEmail",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateUserEmail",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {userId , phone}
     */
    async updateUserPhone({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateUserPhone",
          },
          { root: true }
        );

        const response = await api.patch(
          `users/${payload.userId}/phone`,
          payload
        );

        commit("auth/SET_AUTH_USER_DATA", response.data.data, {
          root: true,
        });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateUserPhone",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateUserPhone",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {userId , phone}
     */
    async updateUserBasicInfo({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateUserBasicInfo",
          },
          { root: true }
        );

        const response = await api.patch(
          `users/${payload.userId}/basic-info`,
          payload
        );

        commit("auth/SET_AUTH_USER_DATA", response.data.data, {
          root: true,
        });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateUserBasicInfo",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateUserBasicInfo",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    async updateUserProfilePicture({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateUserProfilePicture",
          },
          { root: true }
        );
        let formData = new FormData();

        formData.append("profilePicture", payload.profilePicture);
        formData.append("userId", payload.userId.toString());

        console.log(payload);

        const response = await api.patch(
          `users/${payload.userId}/profile-picture`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        commit("auth/SET_AUTH_USER_DATA", response.data.data, {
          root: true,
        });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateUserProfilePicture",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateUserProfilePicture",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    async deleteUserAccount({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "deleteUserAccount",
          },
          { root: true }
        );

        const response = await api.delete(`users/${payload.userId}`, payload);

        commit(
          "auth/SET_AUTH_USER_DATA",
          {
            _id: null,
          },
          {
            root: true,
          }
        );

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "deleteUserAccount",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "deleteUserAccount",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    async sendSms({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "sendSms",
          },
          { root: true }
        );

        const response = await api.post("users/send-sms", payload);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "sendSms",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "sendSms",
            error: responseError,
          },
          { root: true }
        );
      }
    },
  },

  getters: {
    user: (state) => {
      return state.user;
    },
    users: (state) => {
      return state.users;
    },

    get_user_by_id: (state) => (id) => {
      return state.users.find((users) => user.id === id);
    },

    sendSmsDialogVisibility(state) {
      return state.sendSmsDialogVisibility;
    },

    isUsernameAvailable(state) {
      return state.isUsernameAvailable;
    },

    addUserDialogVisibility(state) {
      return state.addUserDialogVisibility;
    },

    updateUserContactInfoDialogVisibility(state) {
      return state.updateUserContactInfoDialogVisibility;
    },

    updateUserBasicInfoDialogVisibility(state) {
      return state.updateUserBasicInfoDialogVisibility;
    },

    updateUserProfilePictureDialogVisibility(state) {
      return state.updateUserProfilePictureDialogVisibility;
    },

    deleteUserAccountDialogVisibility(state) {
      return state.deleteUserAccountDialogVisibility;
    },

    deleteUserAccountByAdminDialogVisibility(state) {
      return state.deleteUserAccountByAdminDialogVisibility;
    },
  },
};
