<template>
  <v-dialog v-model="formVisibility" persistent max-width="720px">
    <v-card color="" class="mx-auto" flat max-width="720px">
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <span class="text-h6">DELETE CLASS</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="12" md="5">
          <!-- <v-card color="" class="mx-auto" flat> -->
          <div class="text-center login-image mt-4">
            <img :src="require('@/assets/delete.gif')" alt="logo" width="240" />
          </div>
          <!-- </v-card> -->
        </v-col>
        <v-col cols="12" md="7">
          <div class="form-container pt-6">
            <v-card flat max-width="400px" min-height="320" class="mx-auto">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-alert
                    prominent
                    type="success"
                    class="mx-2 my-2"
                    :value="deleteClassApiActionStatus.status == 'success'"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <p class="text-capitalize">deleted successfully</p>
                      </v-col>
                      <v-col class="shrink"> </v-col>
                    </v-row>
                  </v-alert>
                  <v-alert
                    prominent
                    type="error"
                    class="mx-2 my-2"
                    :value="deleteClassApiActionStatus.status == 'failed'"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <p class="text-capitalize">
                          {{ deleteClassApiActionStatus.error.message }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <v-chip
                      class="ma-2 mb-6"
                      color="#8269B2"
                      label
                      small
                      outlined
                      @click="showQuickHelpDialog()"
                    >
                      <v-icon left size="14"> mdi-help-circle-outline </v-icon>
                      HELP
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="#F92B60"
                    class="pa-5 white--text"
                    block
                    ripple
                    depressed
                    :loading="deleteSubjectApiActionStatusIsActive"
                    :disabled="isDeleteButtonDisabled"
                    @click="deleteClass()"
                  >
                    DELETE CLASS
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="#671cc9"
                    class="pa-5 my-4 white--text"
                    block
                    ripple
                    depressed
                    :disabled="false"
                    @click="cancel()"
                  >
                    {{ cancelButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({
    deleteSubjectApiActionStatusIsActive: false,
    isDeleteButtonDisabled: false,
    cancelButtonText: "CANCEL",
  }),
  computed: {
    formVisibility() {
      return this.$store.getters["classes/deleteClassDialogVisibility"];
    },

    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    _class() {
      return this.$store.getters["classes/_class"];
    },

    deleteClassApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"]("deleteClass");

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        error: { status: "", message: "" },
        isActive: false,
        isRed: false,
      };
    },
  },
  watch: {
    deleteClassApiActionStatus: {
      handler(newValue, oldValue) {
        this.isDeleteButtonDisabled = newValue.isActive;

        if (newValue.status == "success") {
          this.isDeleteButtonDisabled = true;
          this.cancelButtonText = "CLOSE";
        }
      },
    },
  },
  methods: {
    deleteClass() {
      const classId = this._class._id;
      this.$store.dispatch("classes/deleteClass", classId);
    },

    cancel() {
      if (this.deleteClassApiActionStatus.status == "success") {
        this.$router.push({
          name: "Classes",
        });
      }
      this.resetFormVariables();
      this.$store.dispatch("classes/setDeleteClassDialogVisibility", false);
      this.removeDeleteClassApiAction();
    },

    resetFormVariables() {
      this.isSubmitting = false;
      this.deleteClassApiActionStatusIsActive = true;
    },

    removeDeleteClassApiAction() {
      this.$store.dispatch("app/removeApiAction", "deleteClass");
    },

    showQuickHelpDialog() {
      const content = {
        mainTitle: "Delete Class Form",
        messages: [
          {
            title: "Delete Class",
            message: "once you delete the class it cannot be restored",
          },
        ],
      };
      this.$store.dispatch("app/setQuickHelpDialogContent", content);
      this.$store.dispatch("app/setQuickHelpDialogVisibility", true);
    },
  },
};
</script>
