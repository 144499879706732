import { render, staticRenderFns } from "./DeleteUserAccountDialog.vue?vue&type=template&id=050c510b&"
import script from "./DeleteUserAccountDialog.vue?vue&type=script&lang=js&"
export * from "./DeleteUserAccountDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports