export default {
  namespaced: true,
  namespaced: true,
  state: {
    institute: null,
  },
  mutations: {
    setInstitute(state, payload) {
      state.institute = payload;
    },
  },
  actions: {
    registerInstituteForMeetup({ commit, getters }, payload) {
      commit("setLoading", true);
      const institute = getters.institute;
    },

    logout({ commit }) {
      firebase.auth().signOut();
      commit("setInstitute", null);
    },
  },
  getters: {
    institute(state) {
      return state.institute;
    },
  },
};
