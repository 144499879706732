import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import ECharts from "vue-echarts";
import "echarts";
import CountryFlag from "vue-country-flag";
import FlagIcon from "vue-flag-icon";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";

require("@/assets/main.scss");

Vue.config.productionTip = false;
Vue.use(FlagIcon);
Vue.component("country-flag", CountryFlag);
Vue.component("v-chart", ECharts);
Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
