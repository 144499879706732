<template>
  <v-bottom-navigation
    v-model="bottomNavigationValue"
    color="primary"
    fixed
    app
  >
    <v-btn
      v-for="item in items"
      :key="item.title"
      :to="item.to"
      :value="item.title"
      class="bottom-nav-buttons"
    >
      <span>{{ item.title }}</span>

      <v-icon size="18">{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "BottomNavigation",
  components: {},
  data: () => ({}),
  computed: {
    items() {
      return this.$store.getters["app/bottomNavigationItems"];
    },
    bottomNavigationValue: {
      get: function () {
        return this.$store.getters["app/bottomNavigationValue"];
      },
      set: function (newValue) {
        this.$store.dispatch("app/setBottomNavigationValue", newValue);
      },
    },
  },
};
</script>

<style scoped>
.bottom-nav-buttons {
  height: 56px !important;
  background: #fff !important;
}
</style>
