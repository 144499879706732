import api from "@/api";

export default {
  namespaced: true,

  state: () => ({
    accessToken: null,
    refreshToken: null,

    user: {
      _id: "",
    },

    authTeacher: {
      _id: "",
    },

    authStudent: {
      _id: null,
      user: null,
    },

    otpCode: {
      userId: null,
      resetCode: null,
      email: {
        email: null,
        status: false,
        isTried: false,
        message: null,
      },
      phone: {
        phone: null,
        status: false,
        isTried: false,
        message: null,
      },
    },
  }),

  mutations: {
    SET_TOKENS(state, payload) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },

    SET_OTP_CODE(state, payload) {
      state.otpCode = payload;
    },

    SET_AUTH_USER_DATA(state, payload) {
      state.user = payload;
    },

    SET_AUTH_USER_TEACHER_DATA(state, payload) {
      state.authTeacher = payload;
    },

    SET_AUTH_USER_STUDENT_DATA(state, payload) {
      state.authStudent = payload;
    },

    SET_AUTH_USER_TYPE(state, payload) {
      state.user.type = [payload];
    },

    SET_BEARER_TOKEN(state, payload) {
      api.defaults.headers.common["Authorization"] = "Bearer " + payload;
    },
  },

  actions: {
    async login({ commit, dispatch }, credentials) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "login",
          },
          { root: true }
        );

        const response = await api.post("auth/login", credentials);

        const tokens = {
          accessToken: response.data.data.accessToken,
          refreshToken: response.data.data.refreshToken,
        };

        commit("SET_TOKENS", tokens);

        dispatch("attempt", tokens.accessToken);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          { name: "login" },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "login",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    async attempt({ commit }, accessToken) {
      try {
        commit("SET_BEARER_TOKEN", accessToken);
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          { name: "authUserDetails" },
          { root: true }
        );

        const response = await api.get("users/me", {
          //   headers: {
          //     Authorization: "Bearer " + accessToken,
          //   },
        });
        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          { name: "authUserDetails" },
          { root: true }
        );

        commit("SET_AUTH_USER_DATA", response.data.data);
      } catch (error) {
        console.log(error);
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "authUserDetails",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    async registerUser({ commit, dispatch }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "registerUser",
          },
          { root: true }
        );

        const response = await api.post("auth/register", payload);
        const tokens = {
          accessToken: response.data.data.accessToken,
          refreshToken: response.data.data.refreshToken,
        };

        commit("SET_TOKENS", tokens);

        dispatch("attempt", tokens.accessToken);

        // commit("ADD_USER_TO_USERS", response.data.data, { root: true });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "registerUser",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "registerUser",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    async resetPasswordRequest({ commit, dispatch }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "resetPasswordRequest",
          },
          { root: true }
        );

        const response = await api.post("auth/reset-password-request", payload);

        commit("SET_OTP_CODE", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          { name: "resetPasswordRequest" },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "resetPasswordRequest",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    async resetPassword({ commit, dispatch }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "resetPassword",
          },
          { root: true }
        );

        const response = await api.post("auth/reset-password", payload);

        if (
          response.data.status != true ||
          response.data.data.isPasswordChanged != true
        ) {
          let responseError = {
            status: "500",
            message: "server error x",
          };

          commit(
            "app/SET_API_ACTION_STATUS_FAILED",
            {
              name: "resetPassword",
              error: responseError,
            },
            { root: true }
          );
        }

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          { name: "resetPassword" },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "resetPassword",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    setAuthUserType({ commit }, payload) {
      commit(
        "app/SET_API_ACTION_STATUS_PENDING",
        { name: "setAuthUserType" },
        { root: true }
      );
      commit("SET_AUTH_USER_TYPE", payload);
      commit(
        "app/SET_API_ACTION_STATUS_SUCCESS",
        { name: "setAuthUserType" },
        { root: true }
      );
    },

    resetAuthUserTeacher({ commit }, payload) {
      commit("SET_AUTH_USER_TEACHER_DATA", {
        _id: null,
        user: null,
      });
    },

    resetAuthUserStudent({ commit }, payload) {
      commit("SET_AUTH_USER_STUDENT_DATA", {
        _id: null,
        user: null,
      });
    },

    resetAuthUser({ commit }, payload) {
      commit("SET_AUTH_USER_DATA", {
        _id: null,
      });
    },

    logout({ commit }) {
      const user = {
        _id: "",
        // email: "",
        // username: "",
        // name: "",
        // type: ["student"],
        // createdAt: "",
        // accessToken: "",
        // refreshToken: "",
      };
      commit("SET_AUTH_USER_DATA", user);
    },
  },
  getters: {
    authUser(state) {
      return state.user;
    },

    authTeacher(state) {
      return state.authTeacher;
    },

    authStudent(state) {
      return state.authStudent;
    },

    otpCodeStatus(state) {
      return state.otpCode;
    },
  },
};
