<template>
  <v-dialog v-model="formVisibility" persistent max-width="740px">
    <v-card color="" class="mx-auto" flat max-width="740px">
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <span class="text-h6">TERMS AND CONDITIONS</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <!-- <v-card color="" class="mx-auto" flat> -->
          <div class="text-center login-image">
            <img :src="require('@/assets/terms.gif')" alt="logo" width="240" />
          </div>
          <!-- </v-card> -->
        </v-col>
        <v-col cols="12" md="8">
          is Privacy Policy was last updated on December 1, 2021. Thank you for
          joining Udemy. We at Udemy (“classclass.lk”, “we”, “us”) respect your privacy
          and want you to understand how we collect, use, and share data about
          you. This Privacy Policy covers our data collection practices and
          describes your rights regarding your personal data. Unless we link to
          a different policy or state otherwise, this Privacy Policy applies
          when you visit or use the Udemy and CorpU websites, mobile
          applications, APIs, or related services, including CorpU Open (the
          “Services”). It also applies to prospective customers of our business
          and enterprise products. By using the Services, you agree to the terms
          of this Privacy Policy. You shouldn’t use the Services if you don’t
          agree with this Privacy Policy or any other agreement that governs
          your use of the Services. Table of Contents 1. What Data We Get 2. How
          We Get Data About You 3. What We Use Your Data For 4. Who We Share
          Your Data With 5. Security 6. Your Rights 7. Jurisdiction-Specific
          Rules 8. Updates & Contact Info
          <!-- Buttons -->
          <v-col cols="12">
            <v-btn
              color="#671cc9"
              class="white--text"
              block
              ripple
              depressed
              @click="close()"
            >
              I AGREE
            </v-btn>
          </v-col>

          <v-col cols="12">
            <div class="text-right my-4">
              <v-btn
                color="#F92B60"
                class="white--text"
                block
                ripple
                depressed
                :disabled="false"
                @click="close()"
              >
                CLOSE
              </v-btn>
            </div>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({
    message: "",

    isFormDisabled: false,

    updateUserBasicInfoApiActionStatusIsActive: false,

    isSendButtonDisabled: false,

    cancelButtonText: "CLOSE",
  }),
  computed: {
    formVisibility() {
      return this.$store.getters["users/sendSmsDialogVisibility"];
    },

    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    teacher() {
      return this.$store.getters["teachers/teacher"];
    },

    sendSmsApiActionStatus() {
      const status = this.$store.getters["app/apiActionByName"]("sendSms");

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        error: { status: "", message: "" },
        isActive: false,
        isRed: false,
      };
    },

    isValidStudent() {
      if (
        this.authUser._id &&
        this.authUser.type[0] == "student" &&
        this.authUser.phone
      ) {
        return true;
      }

      return false;
    },
  },
  watch: {
    sendSmsApiActionStatus: {
      handler(newValue, oldValue) {
        this.isFormDisabled = newValue.isActive;

        if (newValue.status == "success") {
          this.isSendButtonDisabled = true;
          this.isFormDisabled = true;
        }
      },
    },

    authUser: {
      handler(newValue, oldValue) {
        if (newValue.name) {
        }

        if (newValue.gender) {
        }
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      this.resetFormVariables();
    },

    sendSms() {
      this.isFormDisabled = true;
      const payload = {
        userId: this.authUser._id,
        userPhone: this.authUser.phone,
        teacherPhoneNumber: this.teacher.user.phone,
        message: this.message,
      };
      console.log(payload);
      this.$store.dispatch("users/sendSms", payload);
    },

    close() {
      this.resetValidationErrors();

      this.removeSendSmsApiAction();
      this.resetFormVariables();

      this.$store.dispatch("users/setSendSmsDialogVisibility", false);
    },

    resetFormVariables() {
      this.isFormDisabled = false;
      this.message = "";
    },

    removeSendSmsApiAction() {
      this.$store.dispatch("app/removeApiAction", "sendSms");
    },

    resetValidationErrors() {
      requestAnimationFrame(() => {
        this.$refs.basicInfoObserver.reset();
      });
    },

    showQuickHelpDialog() {
      const content = {
        mainTitle: "Send SMS to a Teacher",
        messages: [
          {
            title: "Send SMS to a Teacher",
            message:
              "To send sms to a teacher you need to have a student account with added valid mobile number. Otherwise the sms will not be sent",
          },
        ],
      };
      this.$store.dispatch("app/setQuickHelpDialogContent", content);
      this.$store.dispatch("app/setQuickHelpDialogVisibility", true);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
