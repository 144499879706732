import api from "@/api";

export default {
  namespaced: true,
  state: {
    subjects: [],
    subject: {
      _id: "",
      name: "",
      code: "",
      createdBy: "",
    },
    createSubjectDialogVisibility: false,
    updateSubjectDialogVisibility: false,
    deleteSubjectDialogVisibility: false,
  },
  mutations: {
    SET_SUBJECTS(state, payload) {
      state.subjects = payload;
    },

    SET_SUBJECT(state, payload) {
      state.subject = payload;
    },

    ADD_SUBJECT_TO_SUBJECTS(state, payload) {
      const subjects = [payload, ...state.subjects];
      state.subjects = subjects;
    },

    SET_CREATE_SUBJECT_DIALOG_VISIBILITY(state, payload) {
      state.createSubjectDialogVisibility = payload;
    },

    SET_DELETE_SUBJECT_DIALOG_VISIBILITY(state, payload) {
      state.deleteSubjectDialogVisibility = payload;
    },
    SET_UPDATE_SUBJECT_DIALOG_VISIBILITY(state, payload) {
      state.updateSubjectDialogVisibility = payload;
    },
  },
  actions: {
    /**
     *
     * @param {*} param0
     */
    async getSubjects({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getSubjects",
          },
          { root: true }
        );
        const response = await api.get("subjects");

        commit("SET_SUBJECTS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getSubjects",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getSubjects",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload subjectId
     */
    async getSubjectById({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getSubjectById",
          },
          { root: true }
        );
        const response = await api.get(`subjects/${payload}`);

        commit("SET_SUBJECT", response.data.data);

        // it's better to handle status=false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getSubjectById",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getSubjectById",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {name, code, createdBy}
     */
    async createSubject({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createSubject",
          },
          { root: true }
        );

        const response = await api.post("subjects", payload);

        commit("ADD_SUBJECT_TO_SUBJECTS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createSubject",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createSubject",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {subjectId}
     */
    async deleteSubject({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "deleteSubject",
          },
          { root: true }
        );

        const response = await api.delete(`subjects/${payload.subjectId}`);

        if (
          response.data &&
          response.data.status == true &&
          response.data.data._id
        ) {
          commit(
            "app/SET_API_ACTION_STATUS_SUCCESS",
            {
              name: "deleteSubject",
            },
            { root: true }
          );
        } else {
          let responseError = {
            status: "204",
            message: "invalid input",
          };
          commit(
            "app/SET_API_ACTION_STATUS_FAILED",
            {
              name: "deleteSubject",
              error: responseError,
            },
            { root: true }
          );
        }

        /**
         * ! Remove deleted item from the stroe.
         */
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "deleteSubject",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {subjectId, name, code, userId}
     */
    async updateSubject({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateSubject",
          },
          { root: true }
        );

        const response = await api.patch(
          `subjects/${payload.subjectId}`,
          payload
        );

        commit("SET_SUBJECT", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateSubject",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateSubject",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {subjectId , name, userId}
     */
    async updateSubjectName({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateSubjectName",
          },
          { root: true }
        );

        const response = await api.patch(
          `subjects/${payload.subjectId}/name`,
          payload
        );

        commit("SET_SUBJECT", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateSubjectName",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateSubjectName",
            error: responseError,
          },
          { root: true }
        );
      }
    },
    /**
     *
     * @param {*} param0
     * @param {*} payload {subjectId , name, userId}
     */
    async updateSubjectCode({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateSubjectCode",
          },
          { root: true }
        );

        const response = await api.patch(
          `subjects/${payload.subjectId}/code`,
          payload
        );

        commit("SET_SUBJECT", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateSubjectCode",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateSubjectCode",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    setCreateSubjectDialogVisibility({ commit }, payload) {
      commit("SET_CREATE_SUBJECT_DIALOG_VISIBILITY", payload);
    },

    setUpdateSubjectDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_SUBJECT_DIALOG_VISIBILITY", payload);
    },

    setDeleteSubjectDialogVisibility({ commit }, payload) {
      commit("SET_DELETE_SUBJECT_DIALOG_VISIBILITY", payload);
    },
  },

  getters: {
    subjects(state) {
      return state.subjects;
    },

    subject(state) {
      return state.subject;
    },

    createSubjectDialogVisibility(state) {
      return state.createSubjectDialogVisibility;
    },

    updateSubjectDialogVisibility(state) {
      return state.updateSubjectDialogVisibility;
    },

    deleteSubjectDialogVisibility(state) {
      return state.deleteSubjectDialogVisibility;
    },
  },
};
