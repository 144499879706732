export default {
  namespaced: true,
  state: {
    student: null,
  },
  mutations: {
    setStudents(state, payload) {
      state.student = payload;
    },
  },
  actions: {
    registerStudentsForMeetup({ commit, getters }, payload) {
      commit("setLoading", true);
      const student = getters.student;
    },

    logout({ commit }) {
      firebase.auth().signOut();
      commit("setStudents", null);
    },
  },
  getters: {
    student(state) {
      return state.student;
    },
  },
};
