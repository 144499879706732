<template>
  <v-dialog v-model="quickHelipDialogVisibility" persistent width="500">
    <v-card>
      <v-card-title class="text-h5">
        {{ quickHelpDialog.mainTitle }}</v-card-title
      >

      <v-divider></v-divider>
      <div v-for="(item, index) in quickHelpDialog.messages" :key="index">
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          class="ma-6"
        >
          <v-card-text>
            {{ item.title }}
            <br />
            {{ item.message }}
          </v-card-text>
        </v-alert>
      </div>
      <v-divider></v-divider>
      <v-card-text>
        {{ quickHelpDialog.defaultMessage.title }}
        <br />
        {{ quickHelpDialog.defaultMessage.message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeQuickHelp()"> CLOSE </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    helpDialogVisibility: false,
  }),
  computed: {
    quickHelpDialog() {
      const quickHelpDialog = this.$store.getters["app/quickHelpDialog"];
      if (quickHelpDialog) {
        return quickHelpDialog;
      } else {
        return {
          visibility: false,
          mainTitle: "",
          messages: [
            {
              title: "",
              message: "",
            },
          ],

          defaultMessage: {
            title: "CONTACT US",
            message:
              "If you are having any issue please contact our hotline +94779928022",
          },
        };
      }
    },
    quickHelipDialogVisibility() {
      const visibility = this.quickHelpDialog.visibility;
      if (visibility) {
        return true;
      }
      return false;
    },
  },

  methods: {
    closeQuickHelp() {
      this.$store.dispatch("app/setQuickHelpDialogVisibility", false);
      this.$store.dispatch("app/resetQuickHelpDialog", false);
    },
  },
};
</script>

<style scoped>
.login-image {
  padding-top: 10%;
}

.form-container {
  padding-top: 10%;
}
.v-input__icon--prepend-inner .v-icon {
  font-size: 0.9em !important;
}

.v-text-field {
  font-size: 0.8em !important;
}

.v-input .v-label {
  font-size: 0.9em !important;
}

.v-input {
  font-size: 0.9em !important;
}

.v-text-field--outlined.v-input--is-focused fieldset,
.v-text-field--outlined.v-input--has-state fieldset {
  border: 1px solid;
}
</style>
