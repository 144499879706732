export default {
  namespaced: true,

  state: () => ({
    apiActions: {},
    notifications: {},
    bottomNavigation: {
      visibility: true,
      value: null,
      items: [
        { title: "HOME", icon: "mdi-home-outline", to: "/" },
        {
          title: "TEACHERS",
          icon: "mdi-human-male-board",
          to: "/teachers",
        },
        {
          title: "PROFILE",
          icon: "mdi-account-outline",
          to: "/profile",
        },
      ],
    },
    leftSideBar: {},
    rightSideBar: {},
    bottomNotificationVisibility: false,
    bottomNotification: {},

    quickHelpDialogVisibility: false,

    quickHelpDialog: {
      visibility: false,
      mainTitle: "HELP",
      messages: [],

      defaultMessage: {
        title: "CONTACT US",
        message:
          "If you are having any issue please contact our hotline +94779928022",
      },
    },
    serverUrl: "http://206.189.42.3:7070/",
  }),

  mutations: {
    SET_BOTTOM_NAVIGATION_VALUE(state, payload) {
      state.bottomNavigation["value"] = payload;
    },

    SET_API_ACTION_STATUS_PENDING(state, payload) {
      const actions = { ...state.apiActions };
      const actionName = payload["name"];

      actions[actionName] = {
        status: null,
        startTime: Date.now(),
        endTime: null,
        error: {
          status: null,
          message: null,
        },
        isActive: true,
      };
      state.apiActions = actions;
    },

    SET_API_ACTION_STATUS_SUCCESS(state, payload) {
      const actions = { ...state.apiActions };
      const actionName = payload["name"];
      //   const action = actions[actionName];

      actions[actionName]["status"] = "success";
      actions[actionName]["endTime"] = Date.now();
      actions[actionName]["isActive"] = false;

      state.apiActions = actions;
    },

    SET_API_ACTION_STATUS_FAILED(state, payload) {
      const actions = { ...state.apiActions };
      const actionName = payload["name"];

      actions[actionName]["status"] = "failed";
      actions[actionName]["endTime"] = Date.now();
      actions[actionName]["error"] = payload.error;
      actions[actionName]["isActive"] = false;

      state.apiActions = actions;
    },

    REMOVE_API_ACTION(state, payload) {
      const actions = { ...state.apiActions };
      delete actions[payload];
      state.apiActions = actions;
    },

    SET_QUICK_HELP_DIALOG_VISIBILITY(state, payload) {
      state.quickHelpDialog.visibility = payload;
    },

    SET_QUICK_HELP_DIALOG_CONTENT(state, payload) {
      state.quickHelpDialog["mainTitle"] = payload.mainTitle;
      state.quickHelpDialog["messages"] = payload.messages;
    },

    RESET_QUICK_HELP_DIALOG(state) {
      const content = {
        visibility: false,
        mainTitle: "Hello",
        messages: [
          {
            title: "",
            message: "",
          },
        ],

        defaultMessage: {
          title: "CONTACT US",
          message:
            "If you are having any issue please contact our hotline +94779928022",
        },
      };

      state.quickHelpDialog = content;
    },
  },

  actions: {
    setBottomNavigationValue({ commit }, payload) {
      commit("SET_BOTTOM_NAVIGATION_VALUE", payload);
    },

    setQuickHelpDialogContent({ commit }, payload) {
      const content = {
        mainTitle: payload.mainTitle,
        messages: [payload.messages],
      };
      commit("SET_QUICK_HELP_DIALOG_CONTENT", payload);
    },

    resetQuickHelpDialog({ commit }) {
      commit("RESET_QUICK_HELP_DIALOG");
    },

    setQuickHelpDialogVisibility({ commit }, payload) {
      commit("SET_QUICK_HELP_DIALOG_VISIBILITY", payload);
    },

    setApiActionStatusRed({ commit }, payload) {
      commit("SET_API_ACTION_STATUS_RED", payload);
    },

    removeApiAction({ commit }, payload) {
      commit("REMOVE_API_ACTION", payload);
    },
  },
  getters: {
    apiActions: (state) => {
      return state.apiActions;
    },
    apiActionByName: (state) => (actionName) => {
      return state.apiActions[actionName];
    },
    bottomNavigationItems: (state) => {
      return state.bottomNavigation.items;
    },
    bottomNavigationValue: (state) => {
      return state.bottomNavigation.value;
    },
    quickHelpDialog: (state) => {
      return state.quickHelpDialog;
    },
    serverUrl: (state) => {
      return state.serverUrl;
    },
  },
};
