import api from "@/api";

export default {
  namespaced: true,
  state: {
    classes: [],
    _class: {
      _id: "",
    },

    authTeacherClasses: [],
    authTeacherClass: {
      _id: null,
    },

    teacherClasses: [],

    createClassDialogVisibility: false,

    updateClassDialogVisibility: false,
    updateClassDescriptionDialogVisibility: false,
    updateClassSocialMediaDialogVisibility: false,
    updateClassCoverPictureDialogVisibility: false,
    updateClassFeatureImagesDialogVisibility: false,
    deleteClassDialogVisibility: false,
    deleteAuthTeacherClassDialogVisibility: false,
  },
  mutations: {
    SET_CLASSES(state, payload) {
      state.classes = payload;
    },

    SET_TEACHER_CLASSES(state, payload) {
      state.teacherClasses = payload;
    },

    ADD_CLASS_TO_AUTH_TEACHER_CLASSES(state, payload) {
      state.authTeacherClasses = [payload, ...state.authTeacherClasses];
    },

    ADD_CLASS_TO_AUTH_TEACHER_CLASS(state, payload) {
      state.authTeacherClass = payload;
    },

    SET_AUTH_TEACHER_CLASSES(state, payload) {
      state.authTeacherClasses = payload;
    },

    SET_AUTH_TEACHER_CLASS(state, payload) {
      state.authTeacherClass = payload;
    },

    SET_CLASS(state, payload) {
      state._class = payload;
    },

    ADD_CLASS_TO_CLASSS(state, payload) {
      const teachers = [payload, ...state.teachers];
      state.teachers = teachers;
    },

    SET_CREATE_CLASS_DIALOG_VISIBILITY(state, payload) {
      state.createClassDialogVisibility = payload;
    },

    SET_DELETE_CLASS_DIALOG_VISIBILITY(state, payload) {
      state.deleteClassDialogVisibility = payload;
    },

    SET_DELETE_AUTH_TEACHER_CLASS_DIALOG_VISIBILITY(state, payload) {
      state.deleteAuthTeacherClassDialogVisibility = payload;
    },

    SET_UPDATE_CLASS_DIALOG_VISIBILITY(state, payload) {
      state.updateClassDialogVisibility = payload;
    },

    SET_UPDATE_CLASS_COVER_PICTURE_DIALOG_VISIBILITY(state, payload) {
      state.updateClassCoverPictureDialogVisibility = payload;
    },

    SET_UPDATE_CLASS_FEATURE_IMAGES_DIALOG_VISIBILITY(state, payload) {
      state.updateClassFeatureImagesDialogVisibility = payload;
    },
  },
  actions: {
    /**
     *
     * @param {*} param0
     * @param {*} payload userId
     */
    async getClasses({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getClasses",
          },
          { root: true }
        );
        const response = await api.get("classes/");

        commit("SET_CLASSES", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getClasses",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getClasses",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload userId
     */
    async getAuthTeacherClasses({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getAuthTeacherClasses",
          },
          { root: true }
        );
        const response = await api.get(`classes/auth-teacher/${payload}`);

        commit("SET_AUTH_TEACHER_CLASSES", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getAuthTeacherClasses",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getAuthTeacherClasses",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload userId
     */
    async getAuthTeacherClass({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getAuthTeacherClass",
          },
          { root: true }
        );
        const response = await api.get(`classes/${payload}/auth-teacher`);

        commit("SET_AUTH_TEACHER_CLASS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getAuthTeacherClass",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getAuthTeacherClass",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload teacherId
     */
    async getClassById({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getClassById",
          },
          { root: true }
        );
        const response = await api.get(`classes/${payload}`);

        commit("SET_CLASS", response.data.data);

        // it's better to handle status=false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getClassById",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getClassById",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload teacherId
     */
    async getClassesByTeacherId({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getClassesByTeacherId",
          },
          { root: true }
        );
        const response = await api.get(`classes/teacher/${payload}`);

        commit("SET_TEACHER_CLASSES", response.data.data);

        // it's better to handle status=false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getClassesByTeacherId",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getClassesByTeacherId",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload userId
     */
    async getAuthTeacher({ commit }, userId) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getAuthTeacher",
          },
          { root: true }
        );
        const response = await api.get(`teachers/auth-user/${userId}`);

        commit("auth/SET_AUTH_USER_CLASS_DATA", response.data.data, {
          root: true,
        });

        // it's better to handle status=false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getAuthTeacher",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getAuthTeacher",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {name, code, createdBy}
     */
    async createClass({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createClass",
          },
          { root: true }
        );
        console.log(payload);
        const response = await api.post("classes", payload);

        commit("ADD_CLASS_TO_AUTH_TEACHER_CLASS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createClass",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createClass",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {teacherId}
     */
    async deleteClass({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "deleteClass",
          },
          { root: true }
        );

        const response = await api.delete(`classes/${payload}`);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "deleteClass",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "deleteClass",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {teacherId, name, code, userId}
     */
    async updateClass({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateClass",
          },
          { root: true }
        );

        const response = await api.patch(`classes/${payload.classId}`, payload);

        commit("SET_AUTH_TEACHER_CLASS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateClass",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateClass",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {coverPicture, userId, teacherId}
     */
    async updateClassCoverPicture({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateClassCoverPicture",
          },
          { root: true }
        );
        let formData = new FormData();

        formData.append("coverPicture", payload.coverPicture);
        formData.append("userId", payload.userId);
        formData.append("classId", payload.classId);

        console.log(payload);

        const response = await api.patch(
          `classes/${payload.classId}/cover-picture`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        commit("SET_AUTH_TEACHER_CLASS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateClassCoverPicture",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateClassCoverPicture",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {featureImage, userId, teacherId, featureImageNumber}
     */
    async updateClassFeatureImage({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: `updateClassFeatureImage${payload.featureImageNumber}`,
          },
          { root: true }
        );
        let formData = new FormData();

        formData.append("featureImage", payload.featureImage);
        formData.append("userId", payload.userId);
        formData.append("teacherId", payload.teacherId);
        formData.append("classId", payload.classId);

        const response = await api.patch(
          `classes/${payload.classId}/feature-images/${payload.featureImageNumber}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        commit("SET_AUTH_TEACHER_CLASS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: `updateClassFeatureImage${payload.featureImageNumber}`,
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: `updateClassFeatureImage${payload.featureImageNumber}`,
            error: responseError,
          },
          { root: true }
        );
      }
    },

    setCreateClassDialogVisibility({ commit }, payload) {
      commit("SET_CREATE_CLASS_DIALOG_VISIBILITY", payload);
    },

    setUpdateClassDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_CLASS_DIALOG_VISIBILITY", payload);
    },

    setDeleteClassDialogVisibility({ commit }, payload) {
      commit("SET_DELETE_CLASS_DIALOG_VISIBILITY", payload);
    },

    setDeleteAuthTeacherClassDialogVisibility({ commit }, payload) {
      commit("SET_DELETE_AUTH_TEACHER_CLASS_DIALOG_VISIBILITY", payload);
    },

    setUpdateClassCoverPictureDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_CLASS_COVER_PICTURE_DIALOG_VISIBILITY", payload);
    },

    setUpdateClassFeatureImagesDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_CLASS_FEATURE_IMAGES_DIALOG_VISIBILITY", payload);
    },
  },

  getters: {
    authTeacherClasses(state) {
      return state.authTeacherClasses;
    },

    authTeacherClass(state) {
      return state.authTeacherClass;
    },

    teacherClasses(state) {
      return state.teacherClasses;
    },

    classes(state) {
      return state.classes;
    },

    _class(state) {
      return state._class;
    },

    createClassDialogVisibility(state) {
      return state.createClassDialogVisibility;
    },

    updateClassDialogVisibility(state) {
      return state.updateClassDialogVisibility;
    },

    updateClassCoverPictureDialogVisibility(state) {
      return state.updateClassCoverPictureDialogVisibility;
    },

    updateClassFeatureImagesDialogVisibility(state) {
      return state.updateClassFeatureImagesDialogVisibility;
    },

    deleteClassDialogVisibility(state) {
      return state.deleteClassDialogVisibility;
    },

    deleteAuthTeacherClassDialogVisibility(state) {
      return state.deleteAuthTeacherClassDialogVisibility;
    },
  },
};
