<template>
  <h1>
    <v-chart class="chart" :option="option" />
  </h1>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "niversitymouchart",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      option: {
        legend: {},
        tooltip: {},
        dataset: {
          source: [
            ["STATUS", "AMENDMENTS", "APROVED", "PROCEEDING", "IN MOHE"],
            ["UOM", 12, 10, 2, 6],
            ["UOP", 10, 3, 2, 6],
            ["USJ", 1, 1, 2, 6],
            ["OUSL", 7, 4, 2, 6],
            ["USCS", 4, 4, 2, 6],
            ["UOR", 14, 14, 2, 6],
            ["UOJ", 3, 8, 2, 6],
            ["RUSL", 1, 7, 2, 6],
          ],
        },
        xAxis: { type: "category" },
        yAxis: {},

        series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
