<template>
  <v-dialog v-model="formVisibility" persistent max-width="740px">
    <v-card color="" class="mx-auto" flat max-width="740px">
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <span class="text-h6">UPDATE CONTACT INFO</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <!-- <v-card color="" class="mx-auto" flat> -->
          <div class="text-center login-image">
            <img
              :src="require('@/assets/updateContactInfo.gif')"
              alt="logo"
              width="240"
            />
          </div>
          <!-- </v-card> -->
        </v-col>
        <v-col cols="12" md="8">
          <validation-observer
            ref="emailObserver"
            v-slot="{ invalid, handleSubmit }"
          >
            <v-form :disabled="isEmailFormDisabled">
              <v-card flat max-width="440px" class="mx-auto">
                <v-row no-gutters>
                  <v-col cols="8">
                    <validation-provider
                      name="Email"
                      rules="email|required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="email"
                        label="EMAIL"
                        hint="Enter Email"
                        prepend-inner-icon="mdi-email-outline"
                        dense
                        outlined
                        required
                        class="mx-2"
                        :success="valid"
                        :error-messages="errors[0]"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      color="#671cc9"
                      class="white--text"
                      block
                      ripple
                      depressed
                      :loading="updateUserEmailApiActionStatusIsActive"
                      :disabled="isUpdateUserEmailButtonDisabled"
                      @click="handleSubmit(updateUserEmail)"
                    >
                      UPDATE EMAIL
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-alert
                      prominent
                      type="success"
                      class="mx-2 mb-6"
                      :value="
                        updateUserEmailApiActionStatus.status == 'success'
                      "
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <p class="text-capitalize">
                            email updated successfully
                          </p>
                        </v-col>
                        <v-col class="shrink"> </v-col>
                      </v-row>
                    </v-alert>
                    <v-alert
                      prominent
                      type="error"
                      class="mx-2 mb-6"
                      :value="updateUserEmailApiActionStatus.status == 'failed'"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <p class="text-capitalize">
                            {{ updateUserEmailApiActionStatus.error.message }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </validation-observer>

          <validation-observer
            ref="phoneObserver"
            v-slot="{ invalid, handleSubmit }"
          >
            <v-form :disabled="isPhoneFormDisabled">
              <v-card flat max-width="440px" class="mx-auto">
                <v-row no-gutters>
                  <v-col cols="8">
                    <validation-provider
                      name="Phone"
                      rules="required|digits:10"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="phone"
                        label="PHONE NUMBER"
                        hint="Phone Number"
                        prepend-inner-icon="mdi-phone-in-talk-outline"
                        dense
                        outlined
                        password
                        class="mx-2"
                        :success="valid"
                        :error-messages="errors[0]"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      color="#671cc9"
                      class="white--text"
                      block
                      ripple
                      depressed
                      :loading="updateUserPhoneApiActionStatusIsActive"
                      :disabled="isUpdateUserPhoneButtonDisabled"
                      @click="handleSubmit(updateUserPhone)"
                    >
                      UPDATE PHONE
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-alert
                      prominent
                      type="success"
                      class="mx-2 mb-6"
                      :value="
                        updateUserPhoneApiActionStatus.status == 'success'
                      "
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <p class="text-capitalize">
                            phone number updated successfully
                          </p>
                        </v-col>
                        <v-col class="shrink"> </v-col>
                      </v-row>
                    </v-alert>
                    <v-alert
                      prominent
                      type="error"
                      class="mx-2 mb-6"
                      :value="updateUserPhoneApiActionStatus.status == 'failed'"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          <p class="text-capitalize">
                            {{ updateUserPhoneApiActionStatus.error.message }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </validation-observer>

          <v-row no-gutters>
            <v-col cols="12">
              <div class="text-right">
                <v-chip
                  class="ma-4"
                  color="#8269B2"
                  label
                  small
                  outlined
                  @click="showQuickHelpDialog()"
                >
                  <v-icon left size="14"> mdi-help-circle-outline </v-icon>
                  HELP
                </v-chip>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="text-right pa-4">
                <v-btn
                  color="#F92B60"
                  class="white--text"
                  block
                  ripple
                  depressed
                  :disabled="false"
                  @click="close()"
                >
                  CLOSE
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: () => ({
    email: "",
    phone: "",

    isEmailFormDisabled: false,
    isPhoneFormDisabled: false,

    updateUserEmailApiActionStatusIsActive: false,
    updateUserPhoneApiActionStatusIsActive: false,

    isUpdateButtonDisabled: false,
    isUpdateUserEmailButtonDisabled: false,
    isUpdateUserPhoneButtonDisabled: false,
    cancelButtonText: "CLOSE",
  }),
  computed: {
    formVisibility() {
      return this.$store.getters["users/updateUserContactInfoDialogVisibility"];
    },
    authUser() {
      return this.$store.getters["auth/authUser"];
    },

    updateUserEmailApiActionStatus() {
      const status =
        this.$store.getters["app/apiActionByName"]("updateUserEmail");

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        error: { status: "", message: "" },
        isActive: false,
        isRed: false,
      };
    },

    updateUserPhoneApiActionStatus() {
      const status =
        this.$store.getters["app/apiActionByName"]("updateUserPhone");

      if (status) {
        return status;
      }
      return {
        status: null,
        startTime: null,
        endTime: null,
        error: { status: "", message: "" },
        isActive: false,
        isRed: false,
      };
    },
  },
  watch: {
    updateUserEmailApiActionStatus: {
      handler(newValue, oldValue) {
        this.isEmailFormDisabled = newValue.isActive;
        this.updateUserEmailApiActionStatusIsActive = newValue.isActive;
        this.isUpdateUserEmailButtonDisabled = newValue.isActive;

        if (newValue.status == "success") {
          this.isUpdateUserEmailButtonDisabled = true;
          this.isEmailFormDisabled = true;
        }
      },
    },

    updateUserPhoneApiActionStatus: {
      handler(newValue, oldValue) {
        this.isPhoneFormDisabled = newValue.isActive;
        this.updateUserPhoneApiActionStatusIsActive = newValue.isActive;
        this.isUpdateUserPhoneButtonDisabled = newValue.isActive;

        if (newValue.status == "success") {
          this.isUpdateUserPhoneButtonDisabled = true;
          this.isPhoneFormDisabled = true;
        }
      },
    },

    authUser: {
      handler(newValue, oldValue) {
        this.email = "";
        this.phone = "";

        if (newValue.email) {
          this.email = newValue.email;
        }

        if (newValue.phone) {
          this.phone = newValue.phone;
        }
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      this.resetFormVariables();
    },

    updateUserEmail() {
      this.isEmailFormDisabled = true;
      const payload = {
        email: this.email,
        userId: this.authUser._id,
      };
      console.log(payload);
      this.$store.dispatch("users/updateUserEmail", payload);
    },

    updateUserPhone() {
      this.isPhoneFormDisabled = true;
      const payload = {
        phone: this.phone,
        userId: this.authUser._id,
      };

      console.log(payload);

      this.$store.dispatch("users/updateUserPhone", payload);
    },

    close() {
      this.resetValidationErrors();

      this.removeUpdateSubjectNameApiAction();
      this.removeUpdateSubjectCodeApiAction();

      this.$store.dispatch(
        "users/setUpdateUserContactInfoDialogVisibility",
        false
      );
    },

    resetFormVariables() {
      this.updateUserEmailApiActionStatusIsActive = false;
      this.updateUserPhoneApiActionStatusIsActive = false;

      this.isEmailFormDisabled = false;
      this.isPhoneFormDisabled = false;
    },

    removeUpdateSubjectNameApiAction() {
      this.$store.dispatch("app/removeApiAction", "updateUserEmail");
    },

    removeUpdateSubjectCodeApiAction() {
      this.$store.dispatch("app/removeApiAction", "updateUserPhone");
    },

    resetValidationErrors() {
      requestAnimationFrame(() => {
        this.$refs.emailObserver.reset();
      });
      requestAnimationFrame(() => {
        this.$refs.phoneObserver.reset();
      });
    },

    showQuickHelpDialog() {
      const content = {
        mainTitle: "Update Contact Info",
        messages: [
          {
            title: "Email",
            message:
              "Provide unique email. We will reach you through the email. So provide a valid email",
          },
          {
            title: "Phone",
            message:
              "Provide unique phone number. We will reach you through the phone number. So provide a valid phone number",
          },
        ],
      };
      this.$store.dispatch("app/setQuickHelpDialogContent", content);
      this.$store.dispatch("app/setQuickHelpDialogVisibility", true);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
