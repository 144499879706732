import api from "@/api";

export default {
  namespaced: true,
  state: {
    exams: [],
    exam: {
      _id: "",
      name: "",
      code: "",
      createdBy: "",
    },
    createExamDialogVisibility: false,
    updateExamDialogVisibility: false,
    deleteExamDialogVisibility: false,
  },
  mutations: {
    SET_EXAMS(state, payload) {
      state.exams = payload;
    },

    SET_EXAM(state, payload) {
      state.exam = payload;
    },

    ADD_EXAM_TO_EXAMS(state, payload) {
      const exams = [payload, ...state.exams];
      state.exams = exams;
    },

    SET_CREATE_EXAM_DIALOG_VISIBILITY(state, payload) {
      state.createExamDialogVisibility = payload;
    },

    SET_DELETE_EXAM_DIALOG_VISIBILITY(state, payload) {
      state.deleteExamDialogVisibility = payload;
    },

    SET_UPDATE_EXAM_DIALOG_VISIBILITY(state, payload) {
      state.updateExamDialogVisibility = payload;
    },
  },
  actions: {
    /**
     *
     * @param {*} param0
     */
    async getExams({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getExams",
          },
          { root: true }
        );
        const response = await api.get("exams");

        commit("SET_EXAMS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getExams",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getExams",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload subjectId
     */
    async getExamById({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getExam",
          },
          { root: true }
        );
        const response = await api.get(`exams/${payload}`);

        commit("SET_EXAM", response.data.data);

        // It's better to handle stats-false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getExam",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getExam",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {name, code, createdBy}
     */
    async createExam({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createExam",
          },
          { root: true }
        );

        const response = await api.post("exams", payload);

        commit("ADD_EXAM_TO_EXAMS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createExam",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createExam",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {examId}
     */
    async deleteExam({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "deleteExam",
          },
          { root: true }
        );

        const response = await api.delete(`exams/${payload.examId}`);

        if (
          response.data &&
          response.data.status == true &&
          response.data.data._id
        ) {
          commit(
            "app/SET_API_ACTION_STATUS_SUCCESS",
            {
              name: "deleteExam",
            },
            { root: true }
          );
        } else {
          let responseError = {
            status: "204",
            message: "invalid input",
          };
          commit(
            "app/SET_API_ACTION_STATUS_FAILED",
            {
              name: "deleteExam",
              error: responseError,
            },
            { root: true }
          );
        }
        // commit("ADD_EXAM_TO_EXAMS", response.data.data);

        /**
         * ! Remove deleted item from the stroe.
         */
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "deleteExam",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {subjectId , name, code, userId}
     */
    async updateExam({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateExam",
          },
          { root: true }
        );

        const response = await api.patch(`exams/${payload.examId}`, payload);

        commit("SET_EXAM", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateExam",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateExam",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {subjectId , name, userId}
     */
    async updateExamName({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateExamName",
          },
          { root: true }
        );

        const response = await api.patch(
          `exams/${payload.examId}/name`,
          payload
        );

        commit("SET_EXAM", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateExamName",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateExamName",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {examId , code, userId}
     */
    async updateExamCode({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateExamCode",
          },
          { root: true }
        );

        const response = await api.patch(
          `exams/${payload.examId}/code`,
          payload
        );

        commit("SET_EXAM", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateExamCode",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateExamCode",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    setCreateExamDialogVisibility({ commit }, payload) {
      commit("SET_CREATE_EXAM_DIALOG_VISIBILITY", payload);
    },

    setUpdateExamDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_EXAM_DIALOG_VISIBILITY", payload);
    },

    setDeleteExamDialogVisibility({ commit }, payload) {
      commit("SET_DELETE_EXAM_DIALOG_VISIBILITY", payload);
    },
  },

  getters: {
    exams(state) {
      return state.exams;
    },

    exam(state) {
      return state.exam;
    },

    createExamDialogVisibility(state) {
      return state.createExamDialogVisibility;
    },
    updateExamDialogVisibility(state) {
      return state.updateExamDialogVisibility;
    },
    deleteExamDialogVisibility(state) {
      return state.deleteExamDialogVisibility;
    },
  },
};
