import api from "@/api";

export default {
  namespaced: true,
  state: {
    mou: {
      _id: "",
      code: "",
      title: "",
      localInstitute: "",
      foreignInstitute: "",
      status: "",
      submittedBy: null,
      isDeleted: null,
      deletedAt: null,
      createdBy: null,
      createdAt: "",
      updatedAt: "",
    },
    mous: [],
    userMous: [],
    instituteMous: [],

    addMouDialogVisibility: false,
    addEventDialogVisibility: false,
  },
  mutations: {
    SET_MOUS(state, payload) {
      state.mous = payload;
    },

    ADD_MOU_TO_MOUS(state, payload) {
      const mous = [payload, ...state.mous];
      state.mous = mous;
    },

    SET_MOU(state, payload) {
      state.mou = payload;
    },

    SET_USER_MOUS(state, payload) {
      state.userMous = payload;
    },

    SET_INSTITUTE_MOUS(state, payload) {
      state.instituteMous = payload;
    },

    SET_ADD_MOU_DIALOG_VISIBILITY(state, payload) {
      state.addMouDialogVisibility = payload;
    },
    SET_ADD_EVENT_DIALOG_VISIBILITY(state, payload) {
      state.addEventDialogVisibility = payload;
    },
  },
  actions: {
    async getMous({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getMOUS",
          },
          { root: true }
        );
        const response = await api.get("mous");

        commit("SET_MOUS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getMOUS",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getMOUS",
            error: error,
          },
          { root: true }
        );
      }
    },

    async createMou({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createMou",
          },
          { root: true }
        );

        const response = await api.post("mous", payload);

        commit("ADD_MOU_TO_MOUS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createMou",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createMou",
            error: error,
          },
          { root: true }
        );
      }
    },

    async getMouById({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getMouById",
          },
          { root: true }
        );

        const response = await api.get(`mous/${payload}`);

        commit("SET_MOU", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getMouById",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getMouById",
            error: error,
          },
          { root: true }
        );
      }
    },

    async getMousByUserId({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getMousByUserId",
          },
          { root: true }
        );

        const response = await api.get(`mous/user/${payload}`);

        commit("SET_USER_MOUS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getMousByUserId",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getMousByUserId",
            error: error,
          },
          { root: true }
        );
      }
    },

    async getMousByInstituteId({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getMousByInstituteId",
          },
          { root: true }
        );

        const response = await api.get(`mous/institute/${payload}`);

        commit("SET_INSTITUTE_MOUS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getMousByInstituteId",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getMousByInstituteId",
            error: error,
          },
          { root: true }
        );
      }
    },

    async getMouByUserInstitute({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getMouById",
          },
          { root: true }
        );

        const response = await api.get(`mous/${payload}`);

        commit("SET_MOU", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getMouById",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getMouById",
            error: error,
          },
          { root: true }
        );
      }
    },

    addEvent({ commit, dispatch }, payload) {
      commit(
        "app/SET_API_ACTION_STATUS_PENDING",
        {
          name: "addEvent",
        },
        { root: true }
      );

      api
        .post("mous/event", payload)
        .then((response) => {
          //   commit("SET_MOU", response.data.data);
          dispatch("getMouById", payload.mouId);
          commit(
            "app/SET_API_ACTION_STATUS_SUCCESS",
            {
              name: "addEvent",
            },
            { root: true }
          );
        })
        .catch((error) => {
          commit(
            "app/SET_API_ACTION_STATUS_FAILED",
            {
              name: "addEvent",
              error: error,
            },
            { root: true }
          );
        });
    },

    setAddMouDialogVisibility({ commit }, payload) {
      commit("SET_ADD_MOU_DIALOG_VISIBILITY", payload);
    },

    setAddEventDialogVisibility({ commit }, payload) {
      commit("SET_ADD_EVENT_DIALOG_VISIBILITY", payload);
    },
  },
  getters: {
    institutes(state) {
      return state.mous;
    },
    mous(state) {
      return state.mous;
    },
    userMous(state) {
      return state.mous;
    },
    mou(state) {
      return state.mou;
    },
    addMouDialogVisibility(state) {
      return state.addMouDialogVisibility;
    },
    addEventDialogVisibility(state) {
      return state.addEventDialogVisibility;
    },
  },
};
