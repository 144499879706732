import api from "@/api";

export default {
  namespaced: true,
  state: {
    teachers: [],
    teacher: {
      _id: "",
    },
    createTeacherDialogVisibility: false,
    updateTeacherDialogVisibility: false,
    updateTeacherDescriptionDialogVisibility: false,
    updateTeacherSocialMediaDialogVisibility: false,
    updateTeacherCoverPictureDialogVisibility: false,
    updateTeacherFeatureImagesDialogVisibility: false,
    deleteTeacherDialogVisibility: false,
  },
  mutations: {
    SET_TEACHERS(state, payload) {
      state.teachers = payload;
    },

    SET_TEACHER(state, payload) {
      state.teacher = payload;
    },

    ADD_TEACHER_TO_TEACHERS(state, payload) {
      const teachers = [payload, ...state.teachers];
      state.teachers = teachers;
    },

    SET_CREATE_TEACHER_DIALOG_VISIBILITY(state, payload) {
      state.createTeacherDialogVisibility = payload;
    },

    SET_DELETE_TEACHER_DIALOG_VISIBILITY(state, payload) {
      state.deleteTeacherDialogVisibility = payload;
    },

    SET_UPDATE_TEACHER_DIALOG_VISIBILITY(state, payload) {
      state.updateTeacherDialogVisibility = payload;
    },

    SET_UPDATE_TEACHER_DESCRIPTION_DIALOG_VISIBILITY(state, payload) {
      state.updateTeacherDescriptionDialogVisibility = payload;
    },

    SET_UPDATE_TEACHER_SOCIAL_MEDIA_DIALOG_VISIBILITY(state, payload) {
      state.updateTeacherSocialMediaDialogVisibility = payload;
    },

    SET_UPDATE_TEACHER_COVER_PICTURE_DIALOG_VISIBILITY(state, payload) {
      state.updateTeacherCoverPictureDialogVisibility = payload;
    },

    SET_UPDATE_TEACHER_FEATURE_IMAGES_DIALOG_VISIBILITY(state, payload) {
      state.updateTeacherFeatureImagesDialogVisibility = payload;
    },
  },
  actions: {
    /**
     *
     * @param {*} param0
     */
    async getTeachers({ commit }) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getTeachers",
          },
          { root: true }
        );
        const response = await api.get("teachers");

        commit("SET_TEACHERS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getTeachers",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getTeachers",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload teacherId
     */
    async getTeacherById({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getTeacherById",
          },
          { root: true }
        );
        const response = await api.get(`teachers/${payload}`);

        commit("SET_TEACHER", response.data.data);

        // it's better to handle status=false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getTeacherById",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getTeacherById",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload userId
     */
    async getAuthTeacher({ commit }, userId) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "getAuthTeacher",
          },
          { root: true }
        );
        const response = await api.get(`teachers/auth-user/${userId}`);

        commit("auth/SET_AUTH_USER_TEACHER_DATA", response.data.data, {
          root: true,
        });

        // it's better to handle status=false

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "getAuthTeacher",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "getAuthTeacher",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {name, code, createdBy}
     */
    async createTeacher({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "createTeacher",
          },
          { root: true }
        );

        const response = await api.post("teachers", payload);

        commit("ADD_TEACHER_TO_TEACHERS", response.data.data);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "createTeacher",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "createTeacher",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {teacherId}
     */
    async deleteTeacher({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "deleteTeacher",
          },
          { root: true }
        );

        const response = await api.delete(`teachers/${payload.teacherId}`);

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "deleteTeacher",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "deleteTeacher",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {teacherId, name, code, userId}
     */
    async updateTeacherDescription({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateTeacherDescription",
          },
          { root: true }
        );

        const response = await api.patch(
          `teachers/${payload.teacherId}/description`,
          payload
        );

        commit("auth/SET_AUTH_USER_TEACHER_DATA", response.data.data, {
          root: true,
        });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateTeacherDescription",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateTeacherDescription",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {facebook, instagram, .. teacherId , userId}
     */
    async updateTeacherSocialMedia({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateTeacherSocialMedia",
          },
          { root: true }
        );

        const response = await api.patch(
          `teachers/${payload.teacherId}/social-media`,
          payload
        );

        commit("auth/SET_AUTH_USER_TEACHER_DATA", response.data.data, {
          root: true,
        });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateTeacherSocialMedia",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateTeacherSocialMedia",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {coverPicture, userId, teacherId}
     */
    async updateTeacherCoverPicture({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: "updateTeacherCoverPicture",
          },
          { root: true }
        );
        let formData = new FormData();

        formData.append("coverPicture", payload.coverPicture);
        formData.append("userId", payload.userId);
        formData.append("teacherId", payload.teacherId);

        console.log(payload);

        const response = await api.patch(
          `teachers/${payload.teacherId}/cover-picture`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        commit("auth/SET_AUTH_USER_TEACHER_DATA", response.data.data, {
          root: true,
        });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: "updateTeacherCoverPicture",
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: "updateTeacherCoverPicture",
            error: responseError,
          },
          { root: true }
        );
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload {featureImage, userId, teacherId, featureImageNumber}
     */
    async updateTeacherFeatureImage({ commit }, payload) {
      try {
        commit(
          "app/SET_API_ACTION_STATUS_PENDING",
          {
            name: `updateTeacherFeatureImage${payload.featureImageNumber}`,
          },
          { root: true }
        );
        let formData = new FormData();

        formData.append("featureImage", payload.featureImage);
        formData.append("userId", payload.userId);
        formData.append("teacherId", payload.teacherId);

        const response = await api.patch(
          `teachers/${payload.teacherId}/feature-images/${payload.featureImageNumber}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        commit("auth/SET_AUTH_USER_TEACHER_DATA", response.data.data, {
          root: true,
        });

        commit(
          "app/SET_API_ACTION_STATUS_SUCCESS",
          {
            name: `updateTeacherFeatureImage${payload.featureImageNumber}`,
          },
          { root: true }
        );
      } catch (error) {
        let responseError = {
          status: "500",
          message: "server error",
        };

        if (error.response) {
          responseError = error.response.data.error;
        }

        commit(
          "app/SET_API_ACTION_STATUS_FAILED",
          {
            name: `updateTeacherFeatureImage${payload.featureImageNumber}`,
            error: responseError,
          },
          { root: true }
        );
      }
    },

    setCreateTeacherDialogVisibility({ commit }, payload) {
      commit("SET_CREATE_TEACHER_DIALOG_VISIBILITY", payload);
    },

    setUpdateTeacherDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_TEACHER_DIALOG_VISIBILITY", payload);
    },

    setUpdateTeacherDescriptionDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_TEACHER_DESCRIPTION_DIALOG_VISIBILITY", payload);
    },

    setUpdateTeacherSocialMediaDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_TEACHER_SOCIAL_MEDIA_DIALOG_VISIBILITY", payload);
    },

    setDeleteTeacherDialogVisibility({ commit }, payload) {
      commit("SET_DELETE_TEACHER_DIALOG_VISIBILITY", payload);
    },

    setUpdateTeacherCoverPictureDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_TEACHER_COVER_PICTURE_DIALOG_VISIBILITY", payload);
    },

    setUpdateTeacherFeatureImagesDialogVisibility({ commit }, payload) {
      commit("SET_UPDATE_TEACHER_FEATURE_IMAGES_DIALOG_VISIBILITY", payload);
    },
  },

  getters: {
    teachers(state) {
      return state.teachers;
    },

    teacher(state) {
      return state.teacher;
    },

    createTeacherDialogVisibility(state) {
      return state.createTeacherDialogVisibility;
    },

    updateTeacherDialogVisibility(state) {
      return state.updateTeacherDialogVisibility;
    },

    updateTeacherDescriptionDialogVisibility(state) {
      return state.updateTeacherDescriptionDialogVisibility;
    },

    updateTeacherSocialMediaDialogVisibility(state) {
      return state.updateTeacherSocialMediaDialogVisibility;
    },

    updateTeacherCoverPictureDialogVisibility(state) {
      return state.updateTeacherCoverPictureDialogVisibility;
    },

    updateTeacherFeatureImagesDialogVisibility(state) {
      return state.updateTeacherFeatureImagesDialogVisibility;
    },

    deleteTeacherDialogVisibility(state) {
      return state.deleteTeacherDialogVisibility;
    },
  },
};
